import React, { useEffect } from "react";
import './Footer.css'
import Email from '../../assets/carbon_email.svg'
import Fb from '../../assets/fb.svg'
import Tweet from '../../assets/tweet.svg'
import Insta from '../../assets/insta.svg'
import LinkedIn from '../../assets/linked_in.svg'

import Fb2 from '../../assets/fb2.svg'
import Tweet2 from '../../assets/tweet2.svg'
import Insta2 from '../../assets/insta2.svg'
import LinkedIn2 from '../../assets/linked_in2.svg'
import LocalStorageBase64 from './LocalStorageBase64.js'

 const webUrl = process.env.REACT_APP_WEB_URL
//const webUrl = "http://localhost:5006/"


const Footer = () => {
  const [userAcademics, setUserAcademics] = React.useState(false);

  useEffect(() => {
      let loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
      if (
        loginDetails && loginDetails.package_details && loginDetails.package_details.length) {
        setUserAcademics(true);
      }
  }, []);

  useEffect(() => {
    if(document.getElementById('foooter')){
      document.getElementById('foooter').addEventListener("contextmenu",(event) => {
        event.stopPropagation()})
      };
  }, [])
  // useEffect(() => {
  //   if (localStorage.getItem("footerTestimonial")) {
  //     // window.alert("3")
  //     if (localStorage.getItem("footerTestimonial") === "1") {
  //       setTimeout(() => {
  //         window.location.href = '/home#testimonialSection'
  //         localStorage.setItem("footerTestimonial", "0")
  //       }, 5000);


  //     }
  //   }


  // }, [])

  const currentYear = new Date().getFullYear();

  const Company = [
    
    { name: "About Us", link: "about-us" },
    { name: "Certifications", link: "https://certifications.goseeko.com/" },
    { name: "Blog", link: "blog/ " },
    { name: "Rewards Points", link: "reward-points" },
    { name: "Media Release", link: "media-release" },
    { name: "Site Map", link: "sitemap" }
  ];
  const Courses = [
    { name: "B.E.", link: "course/register", slug: "be" },
    { name: "B.Com", link: "course/register", slug: "bcom" },
    { name: "B.A.", link: "course/register", slug: "ba" },
    { name: "BBA", link: "course/register", slug: "bba" },
    { name: "BAF", link: "course/register", slug: "baf" },
    { name: "BMS", link: "course/register", slug: "bms" },
    { name: "BSC", link: "course/register", slug: "BSC" }
  ];

  const Resources = [
    "Notes",
    "MCQ",
    "Question Bank",
    "Audio Bites",
    "Express Class",
    "Reference Videos",
    "Solved Question Papers",
  ];

  const resourcesLink = localStorage.userToken ? `studymaterial/${localStorage.getItem("next_url")}` : "course/register"

  const handleCourses = (e, link, slug) => {

    if(!userAcademics){
      localStorage.setItem("course_slugg",btoa(slug))
    }
    window.location.href = `${webUrl}${link}`
  }
  const handleLinkClick = (e, link) => {
    window.location.href = webUrl + link
  }

  const Support = [
    { name: "Contact Us", link: "contact-us" },
    { name: "FAQ", link: "faq" },
  ]
  const email = {
    text: "support@goseeko.com",
    icon: Email,
    link: "https://mail.google.com/mail/u/0/?fs=1&to=support@goseeko.com&su=&body=&bcc=&tf=cm"
  }

  const PrivacyPolicy = { name: "Privacy policy", link: "privacy-policy" }

  const Terms = { name: "Terms & Conditions", link: "term-and-condition" }

  const Refund = { name: "Cancellation & Refund policy", link: "refund-and-cancellation" }

  const Publisher = { name: "Publisher Agreement", link: "user-submission-agreement" }

  const SocialMedia = [
    {
      name: "facebook",
      image: Fb,
      image2: Fb2,
      link: "https://www.facebook.com/goseeko"
    },
    {
      name: "twitter",
      image: Tweet,
      image2: Tweet2,
      link: "https://twitter.com/goseeko"
    },
    {
      name: "instagram",
      image: Insta,
      image2: Insta2,
      link: "https://www.instagram.com/goseeko/"
    },
    {
      name: "linkedIn",
      image: LinkedIn,
      image2: LinkedIn2,
      link: "https://www.linkedin.com/company/goseeko/"
    }
  ]


  return (
    <div id="foooter" className="new_footer_area">
      {/* 
      <div className="" onClick = {(e) => {
      if((window.location.href=== `${webUrl}home`)||(window.location.href=== `${webUrl}home#testimonialSection`)){
      // window.alert("1")
        window.location.href = `${webUrl}home#testimonialSection`
    }
      else{
        // window.alert("2")
        localStorage.setItem("footerTestimonial","1")
        window.location.href = `${webUrl}home`
      }

    }}>
        Testimonial Section
    </div>
    <a href = "https://mail.google.com/mail/u/0/?fs=1&to=support@goseeko.com&su=&body=&bcc=&tf=cm" target = "_blank">
        Send Email
    </a> */}
      <div className="footer_body">
        <div className="goseeko_section">
          <div className="goseeko" style = {{"cursor" : "pointer"}} onClick = {(e)=>{
            window.location.href = process.env.REACT_APP_PHP_DOMAIN
          }}>
            <img src={`${webUrl}goseeko/images/footer_logos.svg`} alt="logo" />
          </div>
          <div className="about_goseeko">
            Goseeko covers the entire spectrum of student’s journey from Academics to Career. From helping them to ace their academics with our personalized study material to providing them with career development resources, our students meet their academic and professional goals.
          </div>
          <div className="social_media_link">
            {SocialMedia.map((elem) => (<a className="facebook" target="_blank" href={elem.link} onMouseOver={(e) => {
                e.currentTarget.firstChild.src = elem.image2
              }} onMouseOut={(e) => {
                e.currentTarget.firstChild.src = elem.image
              }}>
              <img src={elem.image} />
            </a>
            ))}
          </div>
        </div>
        <div className="links">

        <div className = "company_courses">
          <div className="link_column">
          
            <div className="link_headings">
              Company
            </div>
            
            <div className="link_body">
            <div  className = "mobile_web" style={{ "cursor": "pointer"}} onClick = {(e) => {
      if((window.location.href=== `${process.env.REACT_APP_PHP_DOMAIN}`)||(window.location.href=== `${process.env.REACT_APP_PHP_DOMAIN}#testimonialSection`)){
      // window.alert("1")
        window.location.href = `${process.env.REACT_APP_PHP_DOMAIN}#testimonialSection`
    }
      else{
        // window.alert("2")
        localStorage.setItem("footerTestimonial","1")
        window.location.href = `${webUrl}`
      }

    }}>
        Testimonials
    </div>
              {Company.map((elem) => (
                <a className = "mobile_web" style={{ "cursor": "pointer","color": "#EDEDED","display" : "block", "textDecoration" : "none" }} href = {elem.name==="Certifications"?(elem.link) : (webUrl + elem.link)} >
                  {elem.name}
                </a>
              )

              )}
              
             
              
            </div>

          </div>
         
          <div className="link_column">
            <div className="link_headings">
              Courses
            </div>
            <div className="link_body">
              {Courses.map((elem) => (
                <div  className = "mobile_web" style={{ "cursor": "pointer"}} onClick={(e) => handleCourses(e, elem.link, elem.slug)}>
                  {elem.name}
                </div>
              )

              )}
            </div>

          </div></div>
          <div className = "resources_support">
          <div className="link_column">
            <div className="link_headings">
              Resources
            </div>
            <div className="link_body">
              {Resources.map((elem) => (
                <div  className = "mobile_web" style={{ "cursor": "pointer"}} onClick={(e) => handleLinkClick(e, resourcesLink)}>
                  {elem}
                </div>
              )

              )}
            </div>

          </div>
          <div className="link_column">
            <div className="link_headings">
              Support
            </div>
            <div className="link_body">
              {Support.map((elem) => (
                <a className = "mobile_web" style={{ "cursor": "pointer","color": "#EDEDED","display" : "block", "textDecoration" : "none" }} href = {webUrl + elem.link}>
                {elem.name}
              </a>
              )

              )}
              <div className="mail_support">
                <div className="gmail_icon">
                  <img src={email.icon} />
                </div>
                <div className="mail_link">
                  <a className="mail_link" href={`${email.link}`} target="_blank">{email.text}</a>
                </div>

              </div>
            </div>
          </div></div>

        </div>
      </div>
      <hr className = "ruler"/>
      <div className="footer_footer">
        
        <div className="footer_footer_left">
          © 2021 Goseeko
        </div>
        <div className="footer_footer_right">
        <div className="footer_footer_right_up">
        <a style={{ "cursor": "pointer","color": "#EDEDED","display" : "block", "textDecoration" : "none" }} href = {webUrl + Terms.link}>
            {Terms.name} |&nbsp;
          </a>
          <a style={{ "cursor": "pointer","color": "#EDEDED","display" : "block", "textDecoration" : "none" }} href = {webUrl + Refund.link}>
            {Refund.name} |&nbsp;
          </a>
            </div>
            <div className="footer_footer_right_down">
          <a style={{ "cursor": "pointer","color": "#EDEDED","display" : "block", "textDecoration" : "none" }} href = {webUrl + PrivacyPolicy.link}>
            {PrivacyPolicy.name} |&nbsp;
          </a>
          <a style={{ "cursor": "pointer","color": "#EDEDED","display" : "block", "textDecoration" : "none" }} href = {webUrl + Publisher.link}>
            {Publisher.name}
          </a>
          </div>


        </div>
      </div>
    </div>

  );
};

export default Footer;
