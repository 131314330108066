import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import tawkTo from "tawkto-react";
import useQuery from "./Query";
import GetQueryJson from "./QueryJson";
import "./navbar-mob.css";
import "./navbar.css";
import ReactGa, * as ReactGA from "react-ga";
import LocalStorageBase64 from "./LocalStorageBase64";
const apiUrl = process.env.REACT_APP_API_URL;
const careerMainUrl = process.env.REACT_APP_CAREER_MAIN_URL;
const webUrl = process.env.REACT_APP_WEB_URL;
// const webUrl = `http://localhost:5006/`
const strapiUrl = process.env.REACT_APP_STRAPI_URL;

const Navbar = () => {
  const tawkToPropertyId = "598317b7d1385b2b2e285684";
  const tawkToKey = "default";
  const [userLoggedIn, setUserLoggedIn] = React.useState(false);
  const [userAcademics, setUserAcademics] = React.useState(false);
  const [userId, setUserId] = React.useState("");
  const [menu, setMenu] = React.useState(true);
  const [count, setCount] = useState(0);
  const [isGuest, setIsGuest] = useState(
    !localStorage.loginDetails ? true : false
  );
  const query = useQuery();
  const getQueryJson = GetQueryJson();

  const from = query.get("from");

  const [tg1, settg1] = useState(false);
  const [tg2, settg2] = useState(false);
  const [headerData, setHeaderData] = React.useState({
    streams: [],
    courses: [],
    upskill: {
      upskill_first_list: {
        name: "",
        upskill_list_item: [],
      },
      upskill_second_list: {
        name: "",
        upskill_list_item: [],
      },
      upskill_third_list: {
        name: "",
        upskill_list_item: [],
      },
      upskill_fourth_list: {
        name: "",
        upskill_list_item: [],
      },
    },
  });
  // const headerDataConst = {}

  useEffect(() => {
    let gtagScript = document.createElement("script");
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG_TRACKING_ID}`;
    gtagScript.async = true;
    document.body.appendChild(gtagScript);

    let gtagScriptFun = document.createElement("script");
    gtagScriptFun.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${process.env.REACT_APP_GTAG_TRACKING_ID}');`;
    gtagScriptFun.async = true;
    document.body.appendChild(gtagScriptFun);

    let gtagScriptEventFun = document.createElement("script");
    gtagScriptEventFun.innerHTML = `function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') { console.log("run gtag script") } }; gtag('event', 'conversion', { 'send_to': '${process.env.REACT_APP_GTAG_TRACKING_ID}/0eAICJ6sxr4CEJnq3bkC', 'event_callback': callback }); return false; }`;
    gtagScriptEventFun.async = true;
    document.body.appendChild(gtagScriptEventFun);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    // script.innerHTML = `var w=window;var p = w.location.protocol;if(p.indexOf("http") < 0){p = "http"+":";}var d = document;var f = d.getElementsByTagName('script')[0],s = d.createElement('script');s.type = 'text/javascript'; s.async = false; if (s.readyState){s.onreadystatechange = function(){if (s.readyState=="loaded"||s.readyState == "complete"){s.onreadystatechange = null;try{loadwaprops("3zff27a9c312d75dc2f1bb31725fee2712","3z090c67ff9c357317252166f67ca7c5fe","3z35757552f9599d31659da6fa3178870f76f31760fa337fa33b0e2a508c2abb21","3z7ce3bb52936c19fac1035d59550e004a","0.0");}catch(e){}}};}else {s.onload = function(){try{loadwaprops("3zff27a9c312d75dc2f1bb31725fee2712","3z090c67ff9c357317252166f67ca7c5fe","3z35757552f9599d31659da6fa3178870f76f31760fa337fa33b0e2a508c2abb21","3z7ce3bb52936c19fac1035d59550e004a","0.0");}catch(e){}};};s.src =p+"//ma.zoho.in/hub/js/WebsiteAutomation.js";f.parentNode.insertBefore(s, f);` // dev
    script.innerHTML = `var w=window;var p = w.location.protocol;if(p.indexOf("http") < 0){p = "http"+":";}var d = document;var f = d.getElementsByTagName('script')[0],s = d.createElement('script');s.type = 'text/javascript'; s.async = false; if (s.readyState){s.onreadystatechange = function(){if (s.readyState=="loaded"||s.readyState == "complete"){s.onreadystatechange = null;try{loadwaprops('3zff27a9c312d75dc2f1bb31725fee2712','3z090c67ff9c357317252166f67ca7c5fe','3zeab15ef6d8a90dd4c895a9edc8590d09373e5b2db41160910fe524438fc3e164','3z8389cbfee1f2f935ea1774902c1f366d',0.0);}catch(e){}}};}else {s.onload = function(){try{loadwaprops('3zff27a9c312d75dc2f1bb31725fee2712','3z090c67ff9c357317252166f67ca7c5fe','3zeab15ef6d8a90dd4c895a9edc8590d09373e5b2db41160910fe524438fc3e164','3z8389cbfee1f2f935ea1774902c1f366d',0.0);}catch(e){}};};s.src =p+'//ma.zoho.in/hub/js/WebsiteAutomation.js'; f.parentNode.insertBefore(s, f);`; // prod
    script.async = true;
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.src = `https://cdn-in.pagesense.io/js/goseeko/9c0ecc59ec404927b6ca5af1f8ab7da2.js`;
    script2.async = true;
    document.body.appendChild(script2);

    const script3 = document.createElement("script");
    script3.innerHTML = `(function(w,s){var e=document.createElement("script");e.type="text/javascript";e.async=true;e.src="https://cdn-in.pagesense.io/js/goseeko/9c0ecc59ec404927b6ca5af1f8ab7da2.js";var x=document.getElementsByTagName("script")[0];x.parentNode.insertBefore(e,x);})(window,"script");`;
    script3.async = true;
    document.body.appendChild(script3);

    // <script src="https://cdn-in.pagesense.io/js/goseeko/9c0ecc59ec404927b6ca5af1f8ab7da2.js"></script>

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (getQueryJson.utm_source) {
      localStorage.setItem(
        "utm_source_users",
        getQueryJson ? JSON.stringify(getQueryJson) : ""
      );
    }
  }, "");

  React.useEffect(() => {
    setCount(1);
  }, []);

  useEffect(() => {
    if (from === "get-skilled") {
    } else {
      let token = localStorage.getItem("userToken");
      let loginDetails = JSON.parse(localStorage.getItem("loginDetails"));

      if (
        loginDetails &&
        loginDetails.package_details &&
        loginDetails.package_details.length
      ) {
        setUserAcademics(true);
        console.log("I am here wihtout monitor called");
        callMonitorApi();
      }

      if (token) {
        setUserLoggedIn(true);
        setUserId(localStorage.getItem("userId"));
        callVerfiyUser();
      }
    }
  }, [count]);

  React.useEffect(() => {
    let m = tawkTo(tawkToPropertyId, tawkToKey);
  }, []);

  const callLogout = () => {
    let url = `${apiUrl}logout`;
    const options = {
      method: "POST",
      headers: {
        authorization: localStorage.getItem("userToken"),
        "content-type": "application/json",
        app: "react",
      },
      url: url,
    };
    axios(options)
      .then(({ response }) => {
        console.log(response);
        localStorage.clear();
        setUserLoggedIn(false);
        window.location.href = "/";
      })
      .catch((e) => {
        console.log(e);
        localStorage.clear();
        setUserLoggedIn(false);
        window.location.href = "/";
      });
  };

  const callVerfiyUser = () => {
    let headers = {
      authorization: localStorage.getItem("userToken"),
      app: "react",
    };
    axios
      .post(
        apiUrl + "verifyUserToken",
        {},
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.ack === 1) {
          // localStorage.setItem("loginDetails", JSON.stringify(response.data))
        } else {
          //window.alert("1")
          callLogout();
        }
      })
      .catch((err) => {
        //window.alert("2")
        if (!isGuest) callLogout();
        console.log("falkdlakdfadfjflasf");
        console.log(err);
      });
  };

  const callMonitorApi = () => {
    let headers = {
      authorization: localStorage.getItem("userToken"),
      app: "react",
    };
    axios
      .get(apiUrl + "monitor", {
        headers: headers,
      })
      .then((response) => {
        if (response.data.ack === 1) {
          localStorage.setItem("loginDetails", JSON.stringify(response.data));
        } else {
          //window.alert("3")
          callLogout();
        }

        console.log("falkdjflasf");
        console.log(response.data);
      })
      .catch((err) => {
        //window.alert("4")
        callLogout();
        console.log("falkdlakdfadfjflasf");
        console.log(err);
      });
  };

  const toggleNavbarOld = () => {
    setMenu(!menu);
    settg1(false);
    settg2(false);
  };
  const toggleNavbar = (e, realSlug, slug, url) => {
    e.preventDefault();
    if (userAcademics) {
      window.location.href = process.env.REACT_APP_WEB_URL + url;
      return;
    }

    console.log("in toggle navbar");
    console.log(realSlug);
    if (slug.includes("|")) {
      const mySlug = slug.split("|");
      LocalStorageBase64("set", "course_slugg", mySlug[0]);
      LocalStorageBase64("set", "stream_slugg", mySlug[1]);
    } else {
      LocalStorageBase64("set", "course_slugg", slug);
    }
    if (realSlug.includes("|")) {
      const mySlug1 = realSlug.split("|");
      localStorage.setItem("slug_course", mySlug1[0]);
      localStorage.setItem("slug_stream", mySlug1[1]);
    } else {
      localStorage.setItem("slug_course", realSlug);
    }

    setMenu(!menu);
    settg1(false);
    settg2(false);
    window.location.href = process.env.REACT_APP_WEB_URL + url;
  };

  React.useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    axios
      .get(strapiUrl + "header")
      .then((response) => {
        const data = response.data;
        setHeaderData(data);
        // console.log(headerData)
        // console.log(data)
      })
      .catch((e) => {
        console.log(e);
      });

    axios.get(strapiUrl + "header");
    // window.scrollTo(0, 0);
  }, []);

  const classOne = menu
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  const toggleTg1 = () => {
    settg1(!tg1);
    if (tg1) {
      settg2(false);
    }
  };
  const toggleTg2 = () => {
    settg2(!tg2);
  };

  return (
    <React.Fragment>
      <div id="navbar" className="navbar-area">
        <div className="edemy-nav">
          <div className="container-fluid">
            <div className="navbar navbar-expand-lg navbar-light">
              <div>
             {/* <a
                href={"/"}
                // onClick={toggleNavbar}
                className="navbar-brand"
              >
                <img
                  className={"logos"}
                  // style={{height: "75px", width: "90px"}}
                  style={{
                    height: window.innerWidth > 600 ? "75px" : "44px",
                    width: window.innerWidth > 600 ? "90px" : "60px",
                  }}
                  // width={window.innerWidth > 600 ? "130px" : "80px"}
                  // height={window.innerWidth > 600 ? "30px" : "28px"}
                  src={`${process.env.PUBLIC_URL}/goseeko/images/15_aug.jpeg`}
                  alt="logo"
                />
              </a> */}
              <a
                  href={"/"}
                  // onClick={toggleNavbar}
                  className="navbar-brand"
                >
                  <img
                    className={"logos"}
                    width={window.innerWidth > 600 ? "130px" : "80px"}
                    height={window.innerWidth > 600 ? "46px" : "28px"}
                    src={`${process.env.PUBLIC_URL}/goseeko/images/main_logo.png`}
                    alt="logo"
                  />
                </a>
                </div>

              <button
                onClick={toggleNavbarOld}
                className={classTwo}
                id={"modalOpen"}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item megamenu">
                    {/* <a
                      className="nav-link"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        ReactGA.event({
                          category: "User",
                          action: "Student Loan button Clicked",
                        });

                        // window.alert("2")
                        // if(window.location.href === `${webUrl}` || window.location.href === `${webUrl}#student-loan`)
                        // {
                        //   localStorage.setItem("headerLoan","5")

                        // }
                        // else{

                        window.location.href = `${webUrl}home/student-loan`;
                        // }
                      }}
                    >
                      Student Loan
                    </a> */}
                  </li>
                  {!userLoggedIn && !userAcademics ? (
                    <li className="nav-item megamenu">
                      <a href={`${webUrl}universities`} className="nav-link">
                        Universities
                      </a>
                    </li>
                  ) : null}

                  {/* <li className="nav-item megamenu">
                    <a
                      href={`https://certifications.goseeko.com/`}
                      className="nav-link"
                    >
                      Certifications
                    </a>
                  </li> */}

                  <li className="nav-item">
                    <input
                      type="checkbox"
                      checked={tg1}
                      id="course_streams-0"
                    ></input>
                    <a
                      onClick={toggleTg1}
                      className="nav-link"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Study material <i className="bx bx-chevron-down"></i>
                    </a>
                    <ul className="dropdown-menu" id="dropdown-menu-0">
                      {headerData.courses.map((item, i) => {
                        if (item.course_streams.length) {
                          return (
                            <li className="nav-item">
                              <input
                                type="checkbox"
                                checked={tg2}
                                id="course_streams-1"
                              ></input>
                              <a onClick={toggleTg2} className="nav-link">
                                {item.name}
                                <i className="bx bx-chevron-down"></i>
                              </a>

                              <ul
                                className="dropdown-menu"
                                id="dropdown-menu-1"
                              >
                                {item.course_streams.map((stream_item, i) => {
                                  let courseStreamSlug =
                                    stream_item.course_stream_slug;
                                  return (
                                    <li className="nav-item">
                                      <a
                                        // href={stream_item.url}
                                        onClick={(e) =>
                                          toggleNavbar(
                                            e,
                                            stream_item.slug,
                                            courseStreamSlug,
                                            stream_item.url
                                          )
                                        }
                                        className="nav-link"
                                      >
                                        {stream_item.name}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          );
                        } else {
                          let courseStreamSlug1 = item.course_stream_slug;
                          return (
                            <li className="nav-item">
                              <a
                                // href={item.url}
                                onClick={(e) =>
                                  toggleNavbar(
                                    e,
                                    item.slug,
                                    courseStreamSlug1,
                                    item.url
                                  )
                                }
                                className="nav-link"
                              >
                                {item.name}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </li>

                  {/* <li className="nav-item megamenu">
                    <a
                      onClick={() =>
                        (window.location.href =
                          careerMainUrl +
                          "?token=" +
                          (localStorage.getItem("userToken")
                            ? localStorage.getItem("userToken")
                            : "0") +
                          "&app=false")
                      }
                      style={{ cursor: "pointer" }}
                      role="button"
                      className="nav-link"
                    >
                      Upskill
                    </a> */}

                    {/* <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col">
                                                            <ul className="megamenu-submenu">
                                                                <li className="nav-item">
                                                                    <a
                                                                        onClick={toggleNavbar}
                                                                        className="nav-link"
                                                                    >
                                                                        <strong>
                                                                            {
                                                                                headerData.upskill.upskill_first_list
                                                                                    .name
                                                                            }
                                                                        </strong>
                                                                    </a>
                                                                </li>
                                                                {headerData.upskill.upskill_first_list.upskill_list_item.map(
                                                                    (item, i) => {
                                                                        return (
                                                                            <li className="nav-item">
                                                                                <a
                                                                                    onClick={toggleNavbar}
                                                                                    href={item.url}
                                                                                    className="nav-link"
                                                                                >
                                                                                    {item.name}
                                                                                </a>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        </div>

                                                        <div className="col">
                                                            <ul className="megamenu-submenu">
                                                                <li className="nav-item">
                                                                    <a
                                                                        onClick={toggleNavbar}
                                                                        className="nav-link"
                                                                    >
                                                                        <strong>
                                                                            {
                                                                                headerData.upskill.upskill_second_list
                                                                                    .name
                                                                            }
                                                                        </strong>
                                                                    </a>
                                                                </li>
                                                                {headerData.upskill.upskill_second_list.upskill_list_item.map(
                                                                    (item, i) => {
                                                                        return (
                                                                            <li className="nav-item">
                                                                                <a
                                                                                    href={item.url}
                                                                                    onClick={toggleNavbar}
                                                                                    className="nav-link"
                                                                                >
                                                                                    {item.name}
                                                                                </a>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        </div>

                                                        <div className="col">
                                                            <ul className="megamenu-submenu">
                                                                <li className="nav-item">
                                                                    <a
                                                                        onClick={toggleNavbar}
                                                                        className="nav-link"
                                                                    >
                                                                        <strong>
                                                                            {
                                                                                headerData.upskill.upskill_third_list
                                                                                    .name
                                                                            }
                                                                        </strong>
                                                                    </a>
                                                                </li>
                                                                {headerData.upskill.upskill_third_list.upskill_list_item.map(
                                                                    (item, i) => {
                                                                        return (
                                                                            <li className="nav-item">
                                                                                <a
                                                                                    onClick={toggleNavbar}
                                                                                    href={item.url}
                                                                                    className="nav-link"
                                                                                >
                                                                                    {item.name}
                                                                                </a>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        </div>

                                                        <div className="col">
                                                            <ul className="megamenu-submenu">
                                                                <li className="nav-item">
                                                                    <a
                                                                        onClick={toggleNavbar}
                                                                        className="nav-link"
                                                                    >
                                                                        <strong>
                                                                            {
                                                                                headerData.upskill.upskill_fourth_list
                                                                                    .name
                                                                            }
                                                                        </strong>
                                                                    </a>
                                                                </li>
                                                                {headerData.upskill.upskill_fourth_list.upskill_list_item.map(
                                                                    (item, i) => {
                                                                        return (
                                                                            <li className="nav-item">
                                                                                <a
                                                                                    onClick={toggleNavbar}
                                                                                    href={item.url}
                                                                                    className="nav-link"
                                                                                >
                                                                                    {item.name}
                                                                                </a>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul> */}
                  {/* </li> */}

                  {/* <li className="nav-item megamenu">
                    <a href={webUrl + "books"} className="nav-link">
                      Books
                    </a>
                  </li> */}
                  <li className="nav-item megamenu">
                    <a
                      href={process.env.REACT_APP_SKILLCAMPER_MAIN + "careers/"}
                      style={{ cursor: "pointer" }}
                      role="button"
                      className="nav-link"
                    >
                      Careers
                    </a>
                  </li>
                  <li className="nav-item megamenu">
                    <a
                      onClick={() =>
                        (window.location.href =
                          process.env.REACT_APP_PHP_DOMAIN + "/blog/")
                      }
                      style={{ cursor: "pointer" }}
                      role="button"
                      className="nav-link"
                    >
                      Blog
                    </a>
                  </li>

                  {userLoggedIn && userAcademics ? (
                    <li className="nav-item megamenu">
                      <a
                        href={`${webUrl}studymaterial/${localStorage.getItem(
                          "next_url"
                        )}`}
                        className="nav-link"
                      >
                        My Courses
                      </a>
                    </li>
                  ) : null}
                  {userLoggedIn ? (
                    <span
                      onClick={callLogout}
                      className="login-button"
                      style={{
                        backgroundColor: "#0C0CBF",
                        color: "white",
                      }}
                    >
                      Logout
                    </span>
                  ) : (
                    <Link
                      to={
                        "/profile-authentication" +
                        (from == "refer-friend" ? "?from=refer-friend" : "")
                      }
                      className="login-button"
                    >
                      Log in
                    </Link>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
