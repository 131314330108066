import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ReactGa from "react-ga";
import "./App.css";
import "./goseeko/toast.css";

import { ipGetter, sendAccessData } from "./functions/accessLog";
//import iOpener from "./components/iopener/iOpener";
//import NotFound from "./components/audiobites/NotFound";
import Kitabee from "../src/components/kitabee/Kitabee";
import useQuery from "./goseeko/pages/Query";
import NewHomepage from "./goseeko/pages/new_landing_page/NewLandingPage";
ReactGa.initialize(process.env.REACT_APP_GTAG_TRACKING_ID);
const getSkilledUrl = process.env.REACT_APP_GET_SKILLED_URL


const University = lazy(() => import("./components/university/University"));
const ReferredFriendRegister = lazy(() =>
  import("./components/referredFriendRegister/ReferredFriendRegister")
);
const GenLogin = lazy(() =>
  import("./goseeko/pages/GenLogin")
);
const ProfileAuthentication = lazy(() =>
  import("./goseeko/pages/profile-authentication")
);
const ProfileAuthenticationSales = lazy(() =>
  import("./goseeko/pages/profile-authentication-sales")
);
const GetUpskilled = lazy(() => import("./goseeko/pages/GetUpskilled"));
const RoutingForGetSkilled = lazy(() =>
  import("./goseeko/pages/RoutingForGetSkilled")
);
const GeoLocation = lazy(() =>
  import("./goseeko/pages/GeoLocation")
);

const LandingCompaign = lazy(() => import("./goseeko/pages/LandingCompaign"));
const ScholarshipThankYou = lazy(() =>
  import("./goseeko/pages/ScholarshipThankYou")
);
const MediaRelease = lazy(() =>
  import("./goseeko/pages/footer_1/MediaRelease")
);
const AboutUs = lazy(() => import("./goseeko/pages/footer_1/aboutUs"));

const TrialPage = lazy(() => import("./goseeko/pages/footer_1/trialPage"));
const PrivacyPolicy = lazy(() =>
  import("./goseeko/pages/footer_1/PrivacyPolicy")
);
const Error404 = lazy(() =>
  import("./goseeko/pages/Error404.js")
);
const ReferAFriend = lazy(() =>
  import("./goseeko/pages/footer_1/ReferAFriend")
);
const RefundAndCancellationPolicy = lazy(() =>
  import("./goseeko/pages/footer_1/RefundAndCancellation")
);
const TandC = lazy(() =>
  import("./goseeko/pages/footer_1/terms_and_conditions")
);
const RewardProgram = lazy(() =>
  import("./goseeko/pages/footer_1/RewardProgram")
);
const ContactUs = lazy(() => import("./goseeko/pages/footer_1/ContactUs"));
const Faq = lazy(() => import("./goseeko/pages/footer_1/Faq"));

const PublisherAgreement = lazy(() =>
  import("./goseeko/pages/footer_1/PublisherAgreement")
);
const Sitemap = lazy(() => import("./goseeko/pages/footer_1/Sitemap"));
const Steppers = lazy(() => import("./goseeko/stepper_1/Steppers"));
const RegistrationComplete = lazy(() =>
  import("./goseeko/pages/salesRegistration/registrationComplete")
);
const ResetPassword = lazy(() =>
  import("./goseeko/pages/salesRegistration/resetPassword")
);

const apiUrl = process.env.REACT_APP_API_URL;
const careerMainUrl = process.env.REACT_APP_CAREER_MAIN_URL;
const webUrl = process.env.REACT_APP_WEB_URL;

const HandleRedirection = () => {
  const query = useQuery();
  if (query.get("to") == "get-skilled") {
    window.location.href = `${careerMainUrl}?token=${localStorage.getItem("userToken") ? localStorage.getItem("userToken") : ""
      }`;
  } else {
    window.location.href = `${webUrl}`;
  }
};

const FromLoginRegistration = () => {
  const query = useQuery();
  const userId = query.get("user_id");
  if (userId) {
    window.location.replace(process.env.REACT_APP_WEB_URL + (localStorage.getItem("next_url")?`studymaterial/${localStorage.getItem("next_url")}`:``));
  } else {
    window.location.replace(`${webUrl}`);
  }
};

function App() {
  // const { init, UseGTMHookProvider } = useGTM();
  // useEffect(() => init({ id: "GTM-T6B29DB" }), []);

  useEffect(() => {
    
    
    document.oncopy = (e) => e.preventDefault();
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault()});
    





  }, []);
  

  useEffect(() => {
    const clearInterval = setInterval(sendAccessData, 100000);
    return clearInterval;
  }, []);
  useEffect(() => {
    const ipGettr = ipGetter();
    return ipGettr;
  }, []);

  const Spinner = () => {
    return <>Loading..</>;
  };

  return (
    <BrowserRouter>
      <div>
      <Suspense fallback={Spinner}>
        <Switch>
          
            
              <Route exact path="/courses/:id" component={Kitabee} />
            
            {/* <Suspense fallback={Spinner} ><Route
              exact
              path="/reader-new/:contentId?/:epubName?/:epubId?"
              component={NewReader}
            /></Suspense> */}
            {/* <Suspense fallback={Spinner}>
              <Route exact path="/404" component={NotFound} />
            </Suspense> */}
            {/* <Suspense fallback={Spinner}>
              <Route exact path="/iopener" component={iOpener} />
            </Suspense> */}
            
              <Route
                exact
                path="/referred-user-register"
                component={ReferredFriendRegister}
              />
            

            
              <Route
                exact
                path="/university/:universityId/:fromPhp?"
                component={University}
              />
            <Route exact path={"/"} component={window.location.hostname === getSkilledUrl?RoutingForGetSkilled:NewHomepage}/>
            {/* {window.location.hostname === getSkilledUrl && window.alert("1") && 
              
                <Route exact path={"/"} component={RoutingForGetSkilled}></Route>
              
            }
           
            {window.location.hostname != getSkilledUrl && 
              <Route exact path="/" component={NewHomepage} />
            } */}

            
              <Route
                exact
                path="/course/register"
                component={Steppers}
              />
            

      
              <Route
                exact
                path={"/gen-login"}
                component={GenLogin}
              />
              <Route
                exact
                path={"/redirecting"}
                component={GenLogin}
              />
           

       
              <Route
                exact
                path={"/profile-authentication/:GiUserId?"}
                component={ProfileAuthentication}
              />
  
              <Route
                exact
                path={"/profile-authentication-sales"}
                component={ProfileAuthenticationSales}
              />
  
              <Route
                exact
                path={"/scholarshipthankyou"}
                component={ScholarshipThankYou}
              />
  
              <Route
                exact
                path={"/home/getLocation"}
                component={GeoLocation}
              />
    
              <Route
                exact
                path={"/get-skilled"}
                component={GetUpskilled}
              />
  
              <Route
                exact
                path={"/handleRedirection"}
                component={HandleRedirection}
              />
 
              <Route
                exact
                path="/campaign/scholarship"
                component={LandingCompaign}
              />
   
              <Route
                exact
                path={"/fromLoginRegistration"}
                component={FromLoginRegistration}
              />
   
              <Route exact path={"/about-us"} component={AboutUs} />

              <Route exact path={"/trialPage"} component={TrialPage} />
 
              <Route
                exact={true}
                path={"/user-submission-agreement"}
                component={PublisherAgreement}
              />
    
              <Route
                exact
                path={"/media-release"}
                component={MediaRelease}
              />
    
              <Route exact path={"/sitemap"} component={Sitemap} />
  
              <Route
                exact
                path={"/privacy-policy"}
                component={PrivacyPolicy}
              />
   
              <Route
                exact
                path={"/referfriend"}
                component={ReferAFriend}
              />
   
              <Route
                exact
                path={"/refund-and-cancellation"}
                component={RefundAndCancellationPolicy}
              />
      
              <Route
                exact
                path={"/term-and-condition"}
                component={TandC}
              />
        
              <Route
                exact
                path={"/reward-points"}
                component={RewardProgram}
              />
            

            
              <Route exact path={"/contact-us"} component={ContactUs} />
     
              <Route exact path={"/faq"} component={Faq} />
          
              <Route
                exact
                path={"/reset-password/:GiUserId"}
                component={ResetPassword}
              />
           
            
              <Route
                exact
                path={"/registration-complete"}
                component={RegistrationComplete}
              />
            
            
              <Route
                component={Error404}
              />
            
          
        </Switch>
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
