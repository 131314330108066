var CryptoJS = require("crypto-js");
const crypto = require("crypto")

export const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  if (h < 10) h = "0" + h;
  var m = Math.floor((d % 3600) / 60);
  if (m < 10) m = "0" + m;
  var s = Math.floor((d % 3600) % 60);
  if (s < 10) s = "0" + s;
  var hDisplay = h > 0 ? h + ":" : "";
  var mDisplay = m > 0 ? m + ":" : "00:";
  var sDisplay = s > 0 ? s : "00";
  return hDisplay + mDisplay + sDisplay;
};

export const encryption_api_data = (data) => {
  var finalToken = JSON.stringify(data)
  var encryptedData = CryptoJS.AES.encrypt(finalToken, 'my-secret-key@123').toString();
  var shasum = crypto.createHash('sha1')
  shasum.update(finalToken)
  return {
      data: encryptedData,
      integrity: shasum.digest('hex')
  }
};
// export const getUrlForNextStudy = (reverse) => {
//   return process.env.REACT_APP_WEB_URL + (localStorage.getItem("next_url")?`studymaterial/${localStorage.getItem("next_url")}`:``)
  
// }

export const getFormattedDate = (reverse) => {
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();
  let hour = today.getHours();
  let minute = today.getMinutes();
  let sec = today.getSeconds();
  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;
  if (hour < 10) hour = "0" + hour;
  if (minute < 10) minute = "0" + minute;
  if (reverse) {
    return `${year}-${month}-${day} ${hour}:${minute}:${sec}`;
  }
  return `${day}-${month}-${year} ${hour}:${minute}:${sec}`;
};

export let noApi = new Promise((resolve, reject) => {
  setTimeout(() => resolve("done!"), 1000);
});

export const atou = (b64) => {
  return decodeURIComponent(escape(atob(b64)));
};

export const utoa = (data) => {
  return btoa(unescape(encodeURIComponent(data)));
};

export const hashCode = (s) => {
  var h = 0,
    l = s.length,
    i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
};
