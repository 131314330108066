import axios from "axios";
import { getFormattedDate } from "./utilty";
import { browserName, deviceType } from "react-device-detect";

export const ipGetter = () => {
  // http://13.234.61.196:2222/getIp
  axios({
    url: `${process.env.REACT_APP_API_DOMAIN}/getIp`,
    method: "GET",
  })
    .then((res) => {
      console.log(res.data, "ipGETTER");
      localStorage.setItem("user_ip", res.data.ip);
    })
    .catch((e) => console.dir(e.response));
};

export const eventHandler = (eventData) => {
  let eventArray = [];
  let localArray = localStorage.eventLog;
  if (localArray) eventArray = JSON.parse(localArray);
  console.log(eventData);
  if (
    eventData.event_type.includes("event_start") &&
    eventData.completed === 1
  ) {
    for (let i = eventArray.length - 1; i >= 0; i--) {
      let obj = eventArray[i];
      if (obj.event_type === eventData.event_type && obj.completed === 0) {
        eventArray[i] = {
          ...obj,
          ...eventData,
          event_end: getFormattedDate(),
        };

        break;
      }
    }
  } else {
    let data = {
      created: getFormattedDate(),
      event_start: getFormattedDate(),
      device_type: 1,
      fromWebMob: deviceType,
      browserUsed: browserName,
      ip: localStorage.user_ip,
      event_end: getFormattedDate(),
      completed: 1,
      correct: 0,
      levelid: 0,
      skipped: 0,
      totalque: 0,
      read_pages: "",
      total_pages: "",
      wrong: 0,
      user_id:
        localStorage.authUserType === "authenticated"
          ? localStorage.authUserId
          : 0,
      ...eventData,
    };
    eventArray.push(data);
  }
  localStorage.setItem("eventLog", JSON.stringify(eventArray));
};

export const sendAccessData = () => {
  let data = localStorage.getItem("eventLog");
  let incompleteData = [];
  let completeData = [];
  if (data) {
    data = JSON.parse(data);
    if (data.length > 0) {
      incompleteData = data.filter((d) => d.completed === 0);
      completeData = data.filter((d) => d.completed === 1);
      if (completeData.length > 0) {
        axios({
          url: process.env.REACT_APP_ACCESS_LOG_DOMAIN + "/addAccess",
          method: "POST",
          data: `data=${encodeURI(JSON.stringify(completeData))}&device_type=1`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
          .then((res) => {
            console.log(res.data);
            localStorage.setItem("eventLog", JSON.stringify(incompleteData));
          })
          .catch((e) => console.dir(e.response));
      }
    }
  }
};


export const sendAccessDataForSkillcamper = (url, pageName) => {
  if (localStorage.loginDetails && JSON.parse(localStorage.loginDetails) && JSON.parse(localStorage.loginDetails).user_profile && JSON.parse(localStorage.loginDetails).user_profile.id){
    let data = {
      created: getFormattedDate(),
      event_start: getFormattedDate(),
      device_type: 1,
      fromWebMob: deviceType,
      browserUsed: browserName,
      ip: localStorage.user_ip,
      event_end: getFormattedDate(),
      completed: 1,
      correct: 0,
      levelid: 0,
      skipped: 0,
      totalque: 0,
      read_pages: pageName,
      total_pages: "",
      wrong: 0,
      user_id: JSON.parse(localStorage.loginDetails).user_profile.id,
      event_type: "skillcamper_click_event"
    }
    axios({
      url: process.env.REACT_APP_ACCESS_LOG_DOMAIN + "/addAccess",
      method: "POST",
      data: `data=${encodeURI(JSON.stringify([data]))}&device_type=1`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        console.log(res.data);
        window.location.href = url
        // localStorage.setItem("eventLog", JSON.stringify(incompleteData));
      })
      .catch((e) => console.dir(e.response));    
  }else{
    window.location.href = url
  }

};
