// import YouTube from 'react-youtube';
import { sendAccessDataForSkillcamper } from "../../../functions/accessLog";
import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Navbar";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import TAndCLoan from "../../../components/utility/TAndCLoan/t&cLoan";
import Footer from "../Footer";
import CarouselElastic from "react-elastic-carousel";
import ReactGa, * as ReactGA from "react-ga";
import GetQueryJson from "../QueryJson";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import "./desktop_view.css";
import GifEmbed from "./../../../assets/homepageGif.gif";
import "./mobile_view.css";
import Toast from "../../../components/utility/Toast/Toast";
import useQuery from "./../Query";
import renderHTML from "react-render-html";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import group2 from "./group2.png";
import group1 from "./group1.png";
import group3 from "./group3.png";
import WarningIcon from "./warningIcon.svg";
import ErrorIcon from "./errorIcon.svg";
import CheckCircle from "./checkCircle.svg";
import Helmet from "react-helmet";

import VerifyEmailHome from "../../../components/utility/VerifyEmailHome/verifyEmailHome";
import Select from "./Select/Select.jsx";
import { TableFont } from "./desktop_view.css";
import { Link } from "react-router-dom";

const newScript = document.createElement("script");
newScript.innerHTML = `(function(w,d,s,u,f,m,n,o){o='https://survey.zohopublic.in';w[f]=w[f]||function(){(w[f].p=w[f].p||[]).push(arguments);};m=d.createElement(s),n=d.getElementsByTagName(s)[0];m.async=1;m.src=o+u;n.parentNode.insertBefore(m,n);zs_intercept(o,'DxDw8R',{"closeView":1,"displayPeriod":4,"chosenPages":["uat.goseeko.com/study"],"position":2,"hideEndPage":true});})(window, document, 'script', '/api/v1/public/livesurveys/DxDw8R/popup/script', 'zs_intercept');function zsShowPopup(){var i=setInterval(function(){if(window.zsShowFrame!==undefined){window.zsShowFrame();clearInterval(i);}},300);}zsShowPopup();`;
newScript.async = true;
document.body.appendChild(newScript);
// const newScriptCanonical = document.createElement("head");
// newScriptCanonical.innerHTML = `<link rel="canonical" href="https://www.goseeko.com/"/>`
// newScriptCanonical.async = true;
// document.body.appendChild(newScriptCanonical);
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
// const scrollToRef1 = (ref) => ref.current.scrollIntoView()
const strapiWithoutSlash = process.env.REACT_APP_STRAPI_WITHOUT_SLASH;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    boxShadow: "0px 4px 16px 2px rgb(0 0 0 /25%)",
    border: "none",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    padding: "5px",
  },
};

const axios = require("axios");

const breakPointUniversity = [
  //  {width: 1, itemsToShow: 5},
  { width: 1, itemsToShow: 3, itemsToScroll: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 5 },
  { width: 1200, itemsToShow: 5 },
];

const breakPointTestimonial = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 3 },

  // {width: 1, itemsToShow: 1},
  // {width: 550, itemsToShow: 2, itemsToScroll: 2},
  // {width: 768, itemsToShow: 2},
  // {width: 1200, itemsToShow: 2}
];
// const Hackathon = () => {
//   return (
//     <>
//       {window.innerWidth > 600 ? <div className='hackathonContainer'>
//         <div className='hackathonHeader'>

//         </div>
//         <div className='hackathonContent'>
//           The world’s largest learning laboratory for legal industry innovation.
//           <br /><span style={{ color: "#0C0CBF", fontWeight: "bold" }}>March 25 - 27th 2022</span>
//         </div>

//         <a className='hackathonDetails' href={process.env.REACT_APP_WEB_URL + "home/global-legal-hackathon"}>Register Now</a>

//       </div> : <div className='hackathonContainer'>
//         <div className='hackathonHeaderDetails'>
//           <div className='hackathonHeader'>

//           </div>
//           <div className='hackathonDetails'>
//           <a className='hackathonDetails' href={process.env.REACT_APP_WEB_URL + "home/global-legal-hackathon"}>Register Now</a>
//           </div>
//         </div>
//         <div className='hackathonContent'>
//           The world’s largest learning laboratory for legal industry innovation. <span style={{ color: "#0C0CBF", fontWeight: "bold" }}>March 25 - 27th 2022</span>
//         </div>
//       </div>}
//     </>
//   );
// }
const HomeBanner = (props) => {
  const getQueryJson = GetQueryJson();

  useEffect(() => {
    localStorage.setItem(
      "utm_source_users",
      getQueryJson ? JSON.stringify(getQueryJson) : ""
    );
  }, "");

  const [display, setDisplay] = React.useState(false);

  useEffect(() => {
    setDisplay(true);
    try {
      const button = document.querySelector(".control-next");
      button.addEventListener("click", () => {});
      button.click();
    } catch (e) {}
  }, []);

  const settings = {
    infinite: true,
    autoplay: true,
    autoPlaySpeed: 5000,
  };

  return (
    <>
      {display ? (
        <Slider {...settings}>
          {props.homeBanner.map((v, index) => {
            let img = "";
            try {
              img = v.image.url;
            } catch (e) {}
            return (
              <div className="grid" style={{ paddingBottom: "60px" }}>
                <div className="row homeBannerrow">
                  <div className="col-md-6 col-sm-12 col-wd-12">
                    {v.is_Heading_display ? (
                      <div
                        className="banner_heading_slider"
                        style={{ color: v.text_color }}
                      >
                        {v.Heading}
                      </div>
                    ) : (
                      <div
                        className="banner_heading_slider"
                        style={{ color: v.text_color }}
                      ></div>
                    )}
                    {v.is_Heading_display ? (
                      <div
                        className="banner_text_slider"
                        style={{ color: v.text_color }}
                      >
                        {v.text}
                      </div>
                    ) : (
                      <div
                        className="banner_text_slider1"
                        style={{ color: v.text_color }}
                      >
                        {v.text}
                      </div>
                    )}
                    <div
                      className="mobile_without_button_adjust"
                      style={{ textAlign: "center" }}
                    >
                      {v.is_button_display ? (
                        <a>
                          <button
                            className="banner_button_slider"
                            onClick={
                              false &&
                              localStorage.loginDetails &&
                              localStorage.userToken
                                ? () => {
                                    window.location.href =
                                      process.env.REACT_APP_WEB_URL +
                                      (localStorage.getItem("next_url")
                                        ? `studymaterial/${localStorage.getItem(
                                            "next_url"
                                          )}`
                                        : ``);
                                  }
                                : () => {
                                    window.location.href = `${process.env.REACT_APP_WEB_URL}${v.button_link}`;
                                  }
                            }
                            style={{
                              color: v.button_text_color,
                              backgroundColor: v.button_color,
                            }}
                          >
                            {false &&
                            localStorage.loginDetails &&
                            localStorage.userToken
                              ? `Go To Course`
                              : v.button_text}
                          </button>
                        </a>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-wd-12">
                    <div className="myContainer">
                      {window.innerWidth > 600 ? (
                        <link rel="preload" as="image" href={`${img}`} />
                      ) : (
                        <link
                          rel="preload"
                          as="image"
                          href={`${v.image.medium_url}`}
                        />
                      )}

                      <picture>
                        <source
                          media="(max-width: 600px)"
                          srcset={`${v.image.medium_url}`}
                        />

                        <img
                          className="banner_img_slider"
                          src={`${img}`}
                          loading="lazy"
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                {v.is_button_display ? (
                  <div style={{ textAlign: "center" }}>
                    <a href={process.env.REACT_APP_WEB_URL + v.button_link}>
                      <button
                        className="mobile_banner_button_slider"
                        style={{
                          color: v.button_text_color,
                          backgroundColor: v.button_color,
                        }}
                      >
                        {v.button_text}
                      </button>
                    </a>
                  </div>
                ) : null}
              </div>
            );
          })}
        </Slider>
      ) : (
        ""
      )}
    </>
  );
};

const HomeBannerVideo = (props) => {
  const getQueryJson = GetQueryJson();

  useEffect(() => {
    localStorage.setItem(
      "utm_source_users",
      getQueryJson ? JSON.stringify(getQueryJson) : ""
    );
  }, "");

  // const [display, setDisplay] = React.useState(false);

  // useEffect(() => {
  //   setDisplay(true);
  //   try {
  //     const button = document.querySelector(".control-next");
  //     button.addEventListener("click", () => { });
  //     button.click();
  //   } catch (e) { }
  // }, []);

  // const settings = {
  //   infinite: true,
  //   autoplay: true,
  //   autoPlaySpeed: 5000,
  // };

  // const playVideoAndScrol = () => {
  //   player.current.playVideo();
  //   // document.getElementById("youtubeVideo").scrollIntoView(false)
  //   const element = document.getElementById('youtubeVideo');
  //   const elementRect = element.getBoundingClientRect();
  //   const absoluteElementTop = elementRect.top + window.pageYOffset;
  //   const middle = absoluteElementTop - (window.innerHeight / 2);
  //   // window.scrollTo(0, middle);
  //   window.scrollTo({
  //     top: middle,
  //     behavior: 'smooth'
  //   });

  // }

  // const player = useRef();

  // useEffect(() => {
  //   var tag = document.createElement('script');

  //   tag.src = "https://www.youtube.com/iframe_api";
  //   var firstScriptTag = document.getElementsByTagName('script')[0];
  //   firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //   player.current = new YT.Player('youtubeVideo', {
  //     events: {
  //       // 'onReady': onPlayerReady,
  //       // 'onStateChange': onPlayerStateChange
  //     }
  //   });

  // }, [])
  return (
    <>
      <div className="grid">
        <div className="row homeBannerrow">
          <div className="col-md-7 col-sm-12 col-wd-12">
            <div className="banner_heading_slider_video">
              Why carry so many books
            </div>
            <div className="banner_heading_slider_small_video">
              <span>
                Stop wasting time gathering notes, Ace your exams with digital
                notes and more
              </span>
            </div>
            <div className="banner_heading_slider_more_small_video">
              <span>Try for Free and get access to :</span>
            </div>
            <div className="banner_heading_slider_more_small_video_item">
              <img src={CheckCircle}></img>
              <span className="banner_heading_slider_more_small_video_item_text">
                Notes
              </span>
            </div>
            <div className="banner_heading_slider_more_small_video_item">
              <img src={CheckCircle}></img>
              <span className="banner_heading_slider_more_small_video_item_text">
                MCQ’s
              </span>
            </div>
            <div className="banner_heading_slider_more_small_video_item">
              <img src={CheckCircle}></img>
              <span className="banner_heading_slider_more_small_video_item_text">
                Question Bank
              </span>
            </div>
            <div className="banner_heading_slider_more_small_video_item">
              <img src={CheckCircle}></img>
              <span className="banner_heading_slider_more_small_video_item_text">
                Solved Question Paper
              </span>
            </div>
            <div
              className="mobile_without_button_adjust"
              // style={{ textAlign: "center" }}
            >
              <a>
                <button
                  className="banner_button_slider_video"
                  onClick={
                    false && localStorage.loginDetails && localStorage.userToken
                      ? () => {
                          window.location.href = `${process.env.REACT_APP_WEB_URL}study`;
                        }
                      : () => {
                          window.location.href = `${process.env.REACT_APP_WEB_URL}course/register`;
                        }
                  }
                  style={{
                    marginRight: "10px",
                    color: "#FFFEFE",
                    backgroundColor: "#0C0CBF",
                  }}
                >
                  {false && localStorage.loginDetails && localStorage.userToken
                    ? `Go To Course`
                    : "Try for free"}
                </button>
              </a>

              {/* <button
                className="banner_button_slider_video"
                onClick={(e) => playVideoAndScrol()}
                // onClick={(e) => { document.getElementById("youtubeVideo").src = "https://www.youtube.com/embed/SWvGEhyjoFk?rel=0&autoplay=1" }}
                style={{
                  marginLeft: "10px",
                  color: "#0C0CBF",
                  backgroundColor: "white",
                }}
              >
                Play Video
              </button> */}
            </div>
          </div>
          <div className="col-md-5 col-sm-12 col-wd-12">
            <div className="myContainer">
              {/* <YouTube id="youtubeVideo" className="youtubeVideoIframe" videoId="SWvGEhyjoFk" onReady={(e) => { console.log("youtube"); console.log(e); player.current = e.target }} />; */}
              <div className="video-responsive youtubeVideoIframe">
                <img src={GifEmbed} title="Embedded gif" />
              </div>
              {/* <iframe className="youtubeVideoIframe" src="https://player.vimeo.com/video/677686599?h=e4a2dd70bf&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Goseeko_Smart_Study_Material"></iframe><script src="https://player.vimeo.com/api/player.js"></script> */}
              {/* <iframe id="youtubeVideo" className="youtubeVideoIframe" src="https://www.youtube.com/embed/SWvGEhyjoFk?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            </div>
          </div>
          {/* <div style={{ textAlign: "center" }}>

            <a>
              <button
                className="banner_button_slider mobile_banner_button_slider"
                onClick={false && localStorage.loginDetails && localStorage.userToken ? (() => { window.location.href = `${process.env.REACT_APP_WEB_URL}study` }) : (() => { window.location.href = `${process.env.REACT_APP_WEB_URL}course/register` })}
                style={{
                  margin: "8px",
                  color: "#FFFEFE",
                  backgroundColor: "#0C0CBF",
                }}
              >
                {false && localStorage.loginDetails && localStorage.userToken ? `Go To Course` : "Try for free"}
              </button>
            </a>


            <button
              className="banner_button_slider mobile_banner_button_slider"
              onClick={(e) => { document.getElementById("youtubeVideo").src += "&autoplay=1" }}
              style={{
                margin: "8px",
                color: "#0C0CBF",
                backgroundColor: "white",
              }}
            >
              Play Video
            </button>

          </div> */}

          {/* <div style={{ textAlign: "center" }}>
                    <a href={process.env.REACT_APP_WEB_URL + v.button_link}>
                      <button
                        className="mobile_banner_button_slider"
                        style={{
                          color: v.button_text_color,
                          backgroundColor: v.button_color,
                        }}
                      >
                        {v.button_text}
                      </button>
                    </a>
                  </div> */}
        </div>
      </div>
    </>
  );
};

const University = (props) => {
  const carouselRef = useRef(null);

  // useEffect(() => {
  //   try {
  //     let d = window.document.querySelector(".rec-arrow-right");
  //     setInterval(() => {
  //       d.click();
  //     }, 5000);
  //   } catch (e) {}
  // }, []);

  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(props.university.length);
    }
  };

  return (
    <div className="univ-container" style={{ backgroundColor: "#F3F3FC" }}>
      <div className="container">
        <div className="univ-section-title">
          <text>{renderHTML(props.universityTitle)}</text>
        </div>

        <div>
          <CarouselElastic
            breakPoints={breakPointUniversity}
            enableAutoPlay
            ref={carouselRef}
            onPrevStart={onPrevStart}
            onNextStart={onNextStart}
            disableArrowsOnEnd={false}
            infiniteLoop={true}
          >
            {props.university.map((v) => {
              return (
                <>
                  <div className="univ-single-partner-item">
                    <div style={{ padding: "1px", fontSize: "22px" }}>
                      {(() => {
                        try {
                          return (
                            <div>
                              <link
                                rel="preload"
                                as="image"
                                href={`${v.image.url}`}
                              />

                              <LazyLoadImage
                                src={`${v.image.url}`}
                                className="univ-img"
                                alt="image"
                              />
                            </div>
                          );
                        } catch (e) {}
                      })()}

                      <h5
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                          width: "100px",
                          height: "49px",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {v.name}
                      </h5>
                    </div>
                  </div>
                </>
              );
            })}
          </CarouselElastic>
        </div>
      </div>
    </div>
  );
};

const Testimonial = (props) => {
  const carouselRef = useRef(null);
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  useEffect(() => {
    // try {
    //   let d = window.document.querySelector(".rec-arrow-right");
    //   setInterval(() => {
    //     d.click();
    //   }, 5000);
    // } catch (e) {}
  }, []);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(props.testimonial.length);
    }
  };

  return (
    <div
      className="testimonial-container"
      ref={myRef}
      id="testimonialSection"
      onLoad={(e) => {
        if (localStorage.getItem("footerTestimonial")) {
          // window.alert("3")
          if (localStorage.getItem("footerTestimonial") === "1") {
            executeScroll();
            // window.location.href = '/home#testimonialSection'

            localStorage.setItem("footerTestimonial", "0");
          }
        }
      }}
    >
      <div className="container">
        <h3 className="testimonial-section-title">{props.studentLove}</h3>

        <CarouselElastic
          breakPoints={breakPointTestimonial}
          enableAutoPlay
          ref={carouselRef}
          onPrevStart={onPrevStart}
          onNextStart={onNextStart}
          disableArrowsOnEnd={false}
          infiniteLoop={true}
        >
          {props.testimonial.map((v, index) => {
            return (
              <div
                className="single-testimonial-parent"
                style={{ padding: "10px" }}
              >
                <div className="single-testimonial-entry">
                  <div className="">
                    {(() => {
                      try {
                        return (
                          <div className="testimonial-image-container">
                            <link
                              rel="preload"
                              as="image"
                              href={`${v.image.url}`}
                            />
                            <LazyLoadImage
                              src={`${v.image.url}`}
                              className="testimonial-images"
                              alt="image"
                            />
                          </div>
                        );
                      } catch (e) {}
                    })()}
                    <div className="title">
                      <h3 className="testimonial-name">{v.name}</h3>
                      <span className="testimonial-designation">
                        {v.designation}
                      </span>
                    </div>
                    <div className="testimonial-description">
                      <p>{v.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </CarouselElastic>
      </div>
    </div>
  );
};

const LoanSection = (props) => {
  const [openTnc, setOpenTnc] = useState(false);
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.userToken &&
      !JSON.parse(localStorage.getItem("loginDetails")).is_guest
      ? true
      : false
  );
  const [formState, setFormState] = useState(false);
  const [fullName, setFullName] = useState(
    isLoggedIn
      ? JSON.parse(localStorage.loginDetails).user_profile.full_name
      : ""
  );
  const [course, setCourse] = useState(
    isLoggedIn && JSON.parse(localStorage.loginDetails).education_details
      ? JSON.parse(localStorage.loginDetails).education_details.course_title
      : ""
  );
  const [university, setUniversity] = useState(
    isLoggedIn && JSON.parse(localStorage.loginDetails).education_details
      ? JSON.parse(localStorage.loginDetails).education_details.university_name
      : ""
  );
  const [email, setEmail] = useState(
    isLoggedIn ? JSON.parse(localStorage.loginDetails).user_profile.email : ""
  );
  const [mobile, setMobile] = useState(
    isLoggedIn ? JSON.parse(localStorage.loginDetails).user_profile.mobile : ""
  );
  const [loanAmount, setLoanAmount] = useState("Select Loan Amount");
  const [otp, setOtp] = useState(null);
  const [wrongUniversity, setWrongUniversity] = useState(false);
  const [wrongFormatUniversity, setWrongFormatUniversity] = useState(false);
  const [wrongFormatCourse, setWrongFormatCourse] = useState(false);
  const [wrongEmail, setWrongEmail] = useState(false);
  const [wrongMobile, setWrongMobile] = useState(false);
  const [displayOtp, setDisplayOtp] = useState(false);
  const [countDownStart, setCountDownStart] = useState(false);
  const [seconds, setSeconds] = useState(false);
  const [isOpenFullName, setIsOpenFullName] = useState(false);
  const [isOpenCourse, setIsOpenCourse] = useState(false);
  const [isOpenUniversity, setIsOpenUniversity] = useState(false);
  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const [termChecked, setTermChecked] = useState(false);
  const [isOpenLoanAmount, setIsOpenLoanAmount] = useState(false);
  const [isOpenMobile, setIsOpenMobile] = useState(false);
  const [isOpenOtp, setIsOpenOtp] = useState(false);
  const [isEmptyFullName, setIsEmptyFullName] = useState(false);
  const [isEmptyCourse, setIsEmptyCourse] = useState(false);
  const [isEmptyUniversity, setIsEmptyUniversity] = useState(false);
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  const [isEmptyMobile, setIsEmptyMobile] = useState(false);
  const [isEmptyLoanAmount, setIsEmptyLoanAmount] = useState(false);
  const [isEmptyOtp, setIsEmptyOtp] = useState(false);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [mobileErrorMessage, setMobileErrorMessage] = useState(
    "This number is already registered for loan. Please apply after 30 days."
  );
  const [enterNumber, setEnterNumber] = useState(false);
  const [verifyNumber, setVerifyNumber] = useState(false);
  const [clickGetOtp, setClickGetOtp] = useState(false);
  const [termCheckedError, setTermCheckedError] = useState(false);
  const loanOptions = [
    { key: "<1 Lakh" },
    { key: "1 Lakh - 5 Lakh" },
    { key: "5 Lakh - 20 Lakh" },
    { key: "< 20Lakh" },
  ];
  useEffect(() => {
    // try {
    //   let d = window.document.querySelector(".rec-arrow-right");
    //   setInterval(() => {
    //     d.click();
    //   }, 5000);
    // } catch (e) {}
  }, []);

  const handleFullName = (e) => {
    if (e.target.value.length) {
      setIsEmptyFullName(false);
    }
    setIsOpenFullName(true);
    setIsOpenEmail(false);
    setIsOpenLoanAmount(false);
    setIsOpenMobile(false);
    setIsOpenOtp(false);
    setIsOpenCourse(false);
    setIsOpenUniversity(false);
    setFullName(e.target.value);
  };
  const ValidateString = (str, lenCheck) => {
    let count = 0;
    for (var i = 0; i < str.length; i++) {
      if (!/[a-zA-Z .]/.test(str[i])) {
        return false;
      } else if (/[a-zA-Z]/.test(str[i])) {
        count++;
      }
    }
    if (count < lenCheck) {
      return false;
    }
    return true;
  };
  const handleCourse = (e) => {
    const y = !ValidateString(e.target.value, 2);
    if (e.target.value.length) {
      setIsEmptyCourse(false);
    }

    if (y) {
      setWrongFormatCourse(y);
    } else {
      setWrongFormatCourse(false);
    }

    setIsOpenFullName(false);
    setIsOpenEmail(false);
    setIsOpenLoanAmount(false);
    setIsOpenMobile(false);
    setIsOpenOtp(false);
    setIsOpenCourse(true);
    setIsOpenUniversity(false);
    setCourse(e.target.value);
  };
  const ValidateUniversity = (univ) => {
    if (univ.length >= 3) {
      return true;
    } else {
      return false;
    }
  };
  const handleUniversity = (e) => {
    const y = !ValidateString(e.target.value, 3);
    if (e.target.value.length) {
      setIsEmptyUniversity(false);
    }
    if (y) {
      setWrongUniversity(false);
      setWrongFormatUniversity(y);
    } else {
      setWrongFormatUniversity(false);
    }
    const x = !ValidateUniversity(e.target.value);
    if (x) {
      setWrongUniversity(x);
      setWrongFormatUniversity(false);
    } else {
      setWrongUniversity(false);
    }
    if (x && y) {
      setIsEmptyUniversity(false);
    }
    setIsOpenFullName(false);
    setIsOpenEmail(false);
    setIsOpenLoanAmount(false);
    setIsOpenMobile(false);
    setIsOpenCourse(false);
    setIsOpenUniversity(true);
    setIsOpenOtp(false);

    setUniversity(e.target.value);
  };
  const ValidateEmail = (email) => {
    var mailformat =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if (email.match(mailformat) || email.length === 0) {
      return true;
    } else {
      return false;
    }
  };
  const handleEmail = (e) => {
    const x = !ValidateEmail(e.target.value);
    setWrongEmail(x);
    if (x) {
      setIsEmptyEmail(false);
    }
    setIsOpenFullName(false);
    setIsOpenEmail(true);
    setIsOpenLoanAmount(false);
    setIsOpenMobile(false);
    setIsOpenOtp(false);
    setIsOpenCourse(false);
    setIsOpenUniversity(false);
    setEmail(e.target.value);
  };
  const ValidateMobile = (mobile) => {
    if (
      mobile.length != 0 &&
      (mobile.length != 10 || parseInt(mobile) < 6000000000 || isNaN(mobile))
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleMobile = (e) => {
    setIsOpenFullName(false);
    setIsOpenEmail(false);
    setIsOpenLoanAmount(false);
    setIsOpenCourse(false);
    setIsOpenUniversity(false);
    setIsOpenMobile(true);
    setIsOpenOtp(false);
    const x = !ValidateMobile(e.target.value);
    setWrongMobile(x);
    if (x) {
      setIsEmptyMobile(false);
      setIsAlreadyRegistered(false);
      setVerifyNumber(false);
      setEnterNumber(false);
    }
    setMobile(e.target.value);
  };
  const handleLoanAmount = ({ target }) => {
    ReactGA.event({
      category: "User",
      action: "Loan Amount button Clicked",
    });
    if (target.value.length) {
      setIsEmptyLoanAmount(false);
    }
    setIsOpenFullName(false);
    setIsOpenEmail(false);
    setIsOpenCourse(false);
    setIsOpenUniversity(false);
    setIsOpenLoanAmount(true);
    setIsOpenMobile(false);
    setIsOpenOtp(false);
    setLoanAmount(target.value);
  };
  const handleOtp = (e) => {
    if (e.target.value.length) {
      setIsEmptyOtp(false);
    }
    setIsOpenFullName(false);
    setIsOpenEmail(false);
    setIsOpenLoanAmount(false);
    setIsOpenCourse(false);
    setIsOpenUniversity(false);
    setIsOpenMobile(false);
    setIsOpenOtp(true);

    setOtp(e.target.value);
  };
  const handleTermCheck = ({ target }) => {
    setTermChecked(!termChecked);
  };
  const startCountDown = (sec) => {
    if (window.myInterval != undefined && window.myInterval != "undefined") {
      window.clearInterval(window.myInterval);
    }
    setCountDownStart(true);
    window.myInterval = setInterval(() => {
      if (sec > 0) {
        sec = sec - 1;
        setSeconds(sec - 1);
        localStorage.setItem("smsCountDown", sec);
      }
      if (sec === 0) {
        clearInterval(window.myInterval);
        setCountDownStart(false);
      }
    }, 1000);
  };
  const handleGetOtp = (e) => {
    ReactGA.event({
      category: "User",
      action: "Get Otp button Clicked",
    });
    if (mobile === "") {
      setEnterNumber(true);
      setVerifyNumber(false);
      setIsEmptyMobile(false);
      setIsAlreadyRegistered(false);
      setWrongMobile(false);
      return;
    }
    if (wrongMobile === false) {
      setClickGetOtp(true);
      setIsEmptyOtp(false);

      let data = {
        full_name: fullName,
        email: email,
        mobile: mobile,
        loan_amount: loanAmount,
        course: course,
        university: university,
        isLoggedIn: isLoggedIn ? 1 : 0,
        userId:
          localStorage.loginDetails &&
          !JSON.parse(localStorage.getItem("loginDetails")).is_guest
            ? JSON.parse(localStorage.loginDetails).user_profile.id
            : 0,
        getOtp: 1,
      };

      const url = process.env.REACT_APP_API_URL + `applyLoan`;
      const options = {
        method: "POST",
        data: data,
        headers: {
          "content-type": "application/json",
          app: "react",
        },
        url: url,
      };
      console.log(options);
      axios(options)
        .then((resp) => {
          console.log(resp);
          setEnterNumber(false);
          setVerifyNumber(false);
          setIsEmptyMobile(false);
          setIsAlreadyRegistered(false);
          setWrongMobile(false);

          if (resp.data.ack === 2) {
            setDisplayOtp(true);

            axios
              .post(
                process.env.REACT_APP_API_URL + "checkNumberAndSendSmsForLoan",
                { mobile: mobile }
              )
              .then(
                (response) => {
                  if (response.data.ack === 1) {
                    setCountDownStart(true);
                    setSeconds(29);
                    startCountDown(30);
                  } else {
                    console.log(response.data.msg);
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
          }
        })
        .catch((e) => {
          console.log(e.response);
          setVerifyNumber(false);
          setIsEmptyMobile(false);
          setIsAlreadyRegistered(true);
          setMobileErrorMessage(e.response.data.msg);
          setEnterNumber(false);
          setWrongMobile(false);
        });
    }
  };
  const handleActiveFullName = () => {
    setIsOpenFullName(true);
    setIsOpenEmail(false);
    setIsOpenCourse(false);
    setIsOpenUniversity(false);
    setIsOpenLoanAmount(false);
    setIsOpenMobile(false);
    setIsOpenOtp(false);
  };

  const handleActiveCourse = () => {
    setIsOpenFullName(false);
    setIsOpenEmail(false);
    setIsOpenCourse(true);
    setIsOpenUniversity(false);
    setIsOpenLoanAmount(false);
    setIsOpenMobile(false);
    setIsOpenOtp(false);
  };

  const handleActiveUniversity = () => {
    setIsOpenFullName(false);
    setIsOpenEmail(false);
    setIsOpenCourse(false);
    setIsOpenUniversity(true);
    setIsOpenLoanAmount(false);
    setIsOpenMobile(false);
    setIsOpenOtp(false);
  };
  const handleActiveEmail = () => {
    setIsOpenFullName(false);
    setIsOpenEmail(true);
    setIsOpenLoanAmount(false);
    setIsOpenCourse(false);
    setIsOpenUniversity(false);
    setIsOpenMobile(false);
    setIsOpenOtp(false);
  };
  const handleActiveLoanAmount = () => {
    setIsOpenFullName(false);
    setIsOpenEmail(false);
    setIsOpenCourse(false);
    setIsOpenUniversity(false);
    setIsOpenLoanAmount(true);
    setIsOpenMobile(false);
    setIsOpenOtp(false);
  };
  const handleActiveMobile = () => {
    setIsOpenFullName(false);
    setIsOpenEmail(false);
    setIsOpenCourse(false);
    setIsOpenUniversity(false);
    setIsOpenLoanAmount(false);
    setIsOpenMobile(true);
    setIsOpenOtp(false);
  };
  const handleActiveOtp = () => {
    setIsOpenFullName(false);
    setIsOpenEmail(false);
    setIsOpenCourse(false);
    setIsOpenUniversity(false);
    setIsOpenLoanAmount(false);
    setIsOpenMobile(false);
    setIsOpenOtp(true);
  };
  const handleApplyNow = (e) => {
    ReactGA.event({
      category: "User",
      action: "Apply Now button Clicked",
    });
    if (
      wrongEmail ||
      wrongMobile ||
      wrongUniversity ||
      wrongFormatCourse ||
      wrongFormatUniversity
    ) {
      return;
    }
    if (termChecked) {
      setTermCheckedError(false);
    }

    if (fullName === "") {
      setIsEmptyFullName(true);
    } else {
      setIsEmptyFullName(false);
    }

    if (course === "") {
      setIsEmptyCourse(true);
      setWrongFormatCourse(false);
    } else {
      setIsEmptyCourse(false);
    }

    if (university === "") {
      setWrongUniversity(false);
      setWrongFormatUniversity(false);
      setIsEmptyUniversity(true);
    } else {
      setIsEmptyUniversity(false);
    }
    if (email === "") {
      setIsEmptyEmail(true);
      setWrongEmail(false);
    } else {
      setIsEmptyEmail(false);
    }
    if (loanAmount === "Select Loan Amount") {
      setIsEmptyLoanAmount(true);
    } else {
      setIsEmptyLoanAmount(false);
    }
    if (mobile === "") {
      setVerifyNumber(false);
      setIsEmptyMobile(true);
      setWrongMobile(false);
      setIsAlreadyRegistered(false);
      setEnterNumber(false);
    } else {
      setIsEmptyMobile(false);
    }
    if (
      (otp === null && !isLoggedIn) ||
      (otp === null &&
        isLoggedIn &&
        parseInt(
          JSON.parse(localStorage.loginDetails).user_profile.is_otp_verified
        ) !== 1)
    ) {
      if (
        !isLoggedIn ||
        (isLoggedIn &&
          JSON.parse(localStorage.loginDetails).user_profile.mobile !== mobile)
      ) {
        console.log(clickGetOtp);
        console.log(mobile);
        if (clickGetOtp === false && mobile !== "") {
          setVerifyNumber(true);
          setIsEmptyMobile(false);
          setEnterNumber(false);
          setWrongMobile(false);
          setIsAlreadyRegistered(false);
          return;
        }
      }

      setIsEmptyOtp(true);
      setIsWrongOtp(false);
      setOtpErrorMessage("");

      setVerifyNumber(false);
      return;
    } else if (
      fullName !== "" &&
      course !== "" &&
      university !== "" &&
      email !== "" &&
      mobile !== "" &&
      loanAmount !== "Select Loan Amount"
    ) {
      setIsEmptyOtp(false);

      if (!termChecked) {
        setTermCheckedError(true);
        return;
      } else {
        setTermCheckedError(false);
      }
      if (
        !isLoggedIn ||
        (isLoggedIn &&
          JSON.parse(localStorage.loginDetails).user_profile.mobile !== mobile)
      ) {
        if (clickGetOtp === false) {
          setVerifyNumber(true);
          return;
        }
        axios
          .post(
            process.env.REACT_APP_API_URL + "verifyOtpWithLoanFlow",
            { mobile: mobile, otp: otp },
            {
              headers: {
                app: "react",
              },
            }
          )
          .then(
            (response) => {
              console.log(response.data.ack);
              if (response.data.ack === 1) {
                let data = {
                  full_name: fullName,
                  email: email,
                  mobile: mobile,
                  course: course,
                  university: university,
                  loan_amount: loanAmount,
                  isLoggedIn: isLoggedIn,
                  userId: localStorage.loginDetails
                    ? JSON.parse(localStorage.loginDetails).user_profile.id
                    : 0,
                };
                console.log(data);

                const url = process.env.REACT_APP_API_URL + `applyLoan`;
                const options = {
                  method: "POST",
                  data: data,
                  headers: {
                    "content-type": "application/json",
                    app: "react",
                  },
                  url: url,
                };
                axios(options)
                  .then((response) => {
                    setFullName("");
                    setCourse("");
                    setUniversity("");
                    setEmail("");
                    setMobile("");
                    setLoanAmount("Select Loan Amount");
                    setOtp("");
                    setWrongEmail(false);
                    setWrongUniversity(false);
                    setWrongFormatUniversity(false);
                    setWrongFormatCourse(false);
                    setWrongMobile(false);
                    setCountDownStart(false);
                    setSeconds(false);
                    setIsOpenMobile(false);
                    setIsOpenOtp(false);
                    setIsOpenFullName(false);
                    setIsOpenCourse(false);
                    setIsOpenUniversity(false);
                    setIsOpenEmail(false);
                    setIsOpenLoanAmount(false);
                    setIsEmptyFullName(false);
                    setIsEmptyCourse(false);
                    setIsEmptyUniversity(false);
                    setIsEmptyMobile(false);
                    setIsEmptyEmail(false);
                    setIsEmptyLoanAmount(false);
                    setIsEmptyOtp(false);
                    setIsWrongOtp(false);
                    setIsAlreadyRegistered(false);
                    setEnterNumber(false);
                    setVerifyNumber(false);
                    setClickGetOtp(false);
                    setTermCheckedError(false);

                    setFormState(true);
                    executeScroll();
                  })
                  .catch((e) => {
                    console.log(e.response);
                    if (e.response.data.msg !== "something went wrong") {
                      setIsAlreadyRegistered(true);
                      setIsEmptyMobile(false);
                      setEnterNumber(false);
                      setVerifyNumber(false);
                      setWrongMobile(false);
                    }
                  });
              } else {
              }
            },
            (error) => {
              console.log(error.response.data.msg);
              if (
                error.response.data.msg ===
                "Entered OTP is incorrect. Please try again"
              ) {
                setIsWrongOtp(true);
                setIsEmptyOtp(false);
                setOtpErrorMessage("Please enter correct OTP");
              } else {
                setIsWrongOtp(true);
                setIsEmptyOtp(false);
                setOtpErrorMessage(error.response.data.msg);
              }
            }
          );
      } else {
        let data = {
          full_name: fullName,
          email: email,
          mobile: mobile,
          course: course,
          university: university,
          loan_amount: loanAmount,
          isLoggedIn: 1,
          userId: JSON.parse(localStorage.loginDetails).user_profile.id,
        };

        const url = process.env.REACT_APP_API_URL + `applyLoan`;
        const options = {
          method: "POST",
          data: data,
          headers: {
            "content-type": "application/json",
            app: "react",
          },
          url: url,
        };
        axios(options)
          .then((response) => {
            setFullName("");
            setCourse("");
            setUniversity("");
            setEmail("");
            setMobile("");
            setLoanAmount("Select Loan Amount");
            setOtp("");
            setWrongEmail(false);
            setWrongUniversity(false);
            setWrongFormatUniversity(false);
            setWrongFormatCourse(false);
            setWrongMobile(false);
            setCountDownStart(false);
            setSeconds(false);
            setIsOpenMobile(false);
            setIsOpenOtp(false);
            setIsOpenFullName(false);
            setIsOpenCourse(false);
            setIsOpenUniversity(false);
            setIsOpenEmail(false);
            setIsOpenLoanAmount(false);
            setIsEmptyFullName(false);
            setIsEmptyCourse(false);
            setIsEmptyUniversity(false);
            setIsEmptyMobile(false);
            setIsEmptyEmail(false);
            setIsEmptyLoanAmount(false);
            setIsEmptyOtp(false);
            setIsWrongOtp(false);
            setIsAlreadyRegistered(false);
            setEnterNumber(false);
            setVerifyNumber(false);
            setClickGetOtp(false);
            setTermCheckedError(false);

            setFormState(true);
            executeScroll();
          })
          .catch((e) => {
            console.log(e.response);
            if (e.response.data.msg !== "something went wrong") {
              setIsAlreadyRegistered(true);
              setMobileErrorMessage(e.response.data.msg);
              setWrongMobile(false);
              setIsEmptyMobile(false);
            }
          });
      }
    }
  };

  return (
    <div
      className="loan-container"
      ref={myRef}
      id="student-loan"
      onLoad={(e) => {
        if (localStorage.getItem("headerLoan")) {
          if (parseInt(localStorage.getItem("headerLoan")) === 1) {
            setTimeout(() => {
              window.scrollTo(0, myRef.current.offsetTop - 50);
            }, 3000);

            localStorage.setItem("headerLoan", "0");
          }
        }
      }}
    >
      <div
        className="testimonial-section-title-loan"
        style={window.innerWidth < 600 ? { marginBottom: "40px" } : null}
      >
        3 simple steps to apply for an educational loan
      </div>
      <div className="loan-section">
        {/* <div className="loan-left">
          <div className="independent">Be Independent</div>
          <div className="loan-heading">Take a step forward now</div>
          <div className="loan-text">
            Take a{" "}
            <span style={{ color: "#0C0CBF", fontWeight: "bold" }}>
              Student Education loan
            </span>{" "}
            from leading banks for your studies .<br />
            <span style={{ color: "#0C0CBF", fontWeight: "bold" }}>
              Fill the form
            </span>{" "}
            and we will get the best banks to get in touch with you.
            <br />
          </div>
        </div> */}
        {/* {!formState ? (
          <div className="loan-right">
            <div
              className="full-name"
              style={isOpenFullName ? { color: "#0C0CBF" } : null}
              onBlur={(e) => {
                setIsOpenFullName(false);
              }}
            >
              Full Name
            </div>
            <div className="full-name-div">
              <input
                type="text"
                className="full-name-field"
                placeholder="Full Name"
                value={fullName}
                onBlur={(e) => {
                  setIsOpenFullName(false);
                }}
                onChange={(e) => handleFullName(e)}
                style={
                  isOpenFullName
                    ? {
                        border: "1px solid #0C0CBF",
                        "box-shadow": "0px 1px 2px rgba(12, 12, 191, 0.25)",
                      }
                    : null
                }
                onClick={(e) => handleActiveFullName(e)}
              />
              {isEmptyFullName ? (
                <div className="EmptyFieldError">
                  <img style={{ marginRight: "3px" }} src={WarningIcon} />
                  Please fill out this field
                </div>
              ) : null}
            </div>
            <div className="email_amount">
              <div className="email-div">
                <div
                  className="full-name"
                  style={isOpenEmail ? { color: "#0C0CBF" } : null}
                  onBlur={(e) => {
                    setIsOpenEmail(false);
                  }}
                >
                  Email
                </div>
                <input
                  type="text"
                  className="email-field"
                  placeholder="Email"
                  value={email}
                  onBlur={(e) => {
                    setIsOpenEmail(false);
                  }}
                  onChange={(e) => handleEmail(e)}
                  style={
                    isOpenEmail && !wrongEmail
                      ? {
                          border: "1px solid #0C0CBF",
                          "box-shadow": "0px 1px 2px rgba(12, 12, 191, 0.25)",
                        }
                      : wrongEmail
                      ? {
                          border: "1px solid #E60C0B",
                          "box-shadow": "0px 1px 2px rgba(12, 12, 191, 0.25)",
                        }
                      : null
                  }
                  onClick={(e) => handleActiveEmail(e)}
                />
                {isEmptyEmail ? (
                  <div className="EmptyFieldError">
                    <img style={{ marginRight: "3px" }} src={WarningIcon} />
                    Please fill out this field
                  </div>
                ) : null}
                {wrongEmail ? (
                  <div className="EightError">
                    <img className="ErrorIconImg" src={ErrorIcon} />
                    Please enter correct email address
                  </div>
                ) : null}
              </div>
              <div
                className="amount-div"
                onClick={handleActiveLoanAmount}
                onBlur={(e) => {
                  setIsOpenLoanAmount(false);
                }}
              >
                <div
                  className="full-name"
                  style={isOpenLoanAmount ? { color: "#0C0CBF" } : null}
                >
                  Loan Amount
                </div>

                <Select
                  required
                  options={loanOptions}
                  defaultText="Select Loan Amount"
                  onChange={handleLoanAmount}
                  value={loanAmount}
                  filedName="key"
                />

                {isEmptyLoanAmount ? (
                  <div className="EmptyFieldError">
                    <img style={{ marginRight: "3px" }} src={WarningIcon} />
                    Please fill out this field
                  </div>
                ) : null}
              </div>
            </div>
            <div className="email_amount">
              <div className="email-div">
                <div
                  className="full-name"
                  style={isOpenCourse ? { color: "#0C0CBF" } : null}
                  onBlur={(e) => {
                    setIsOpenCourse(false);
                  }}
                >
                  Course
                </div>
                <input
                  type="text"
                  className="email-field"
                  placeholder="Course"
                  value={course}
                  onBlur={(e) => {
                    setIsOpenCourse(false);
                  }}
                  onChange={(e) => handleCourse(e)}
                  style={
                    isOpenCourse && !wrongFormatCourse
                      ? {
                          border: "1px solid #0C0CBF",
                          "box-shadow": "0px 1px 2px rgba(12, 12, 191, 0.25)",
                        }
                      : wrongFormatCourse
                      ? {
                          border: "1px solid #E60C0B",
                          "box-shadow": "0px 1px 2px rgba(12, 12, 191, 0.25)",
                        }
                      : null
                  }
                  onClick={(e) => handleActiveCourse(e)}
                />
                {isEmptyCourse ? (
                  <div className="EmptyFieldError">
                    <img style={{ marginRight: "3px" }} src={WarningIcon} />
                    Please fill out this field
                  </div>
                ) : null}
                {wrongFormatCourse ? (
                  <div className="EightError">
                    <img className="ErrorIconImg" src={ErrorIcon} />
                    Please enter correct course name
                  </div>
                ) : null}
              </div>
              <div className="university-div">
                <div
                  className="full-name"
                  style={isOpenUniversity ? { color: "#0C0CBF" } : null}
                  onBlur={(e) => {
                    setIsOpenUniversity(false);
                  }}
                >
                  University
                </div>
                <input
                  type="text"
                  className="email-field"
                  placeholder="University"
                  value={university}
                  onBlur={(e) => {
                    setIsOpenUniversity(false);
                  }}
                  onChange={(e) => handleUniversity(e)}
                  style={
                    isOpenUniversity &&
                    !wrongUniversity &&
                    !wrongFormatUniversity
                      ? {
                          border: "1px solid #0C0CBF",
                          "box-shadow": "0px 1px 2px rgba(12, 12, 191, 0.25)",
                        }
                      : wrongUniversity || wrongFormatUniversity
                      ? {
                          border: "1px solid #E60C0B",
                          "box-shadow": "0px 1px 2px rgba(12, 12, 191, 0.25)",
                        }
                      : null
                  }
                  onClick={(e) => handleActiveUniversity(e)}
                />
                {isEmptyUniversity ? (
                  <div className="EmptyFieldError">
                    <img style={{ marginRight: "3px" }} src={WarningIcon} />
                    Please fill out this field
                  </div>
                ) : null}
                {wrongUniversity ? (
                  <div className="EightError">
                    <img className="ErrorIconImg" src={ErrorIcon} />
                    University name must be atleast 3 characters long
                  </div>
                ) : null}
                {wrongFormatUniversity ? (
                  <div className="EightError">
                    <img className="ErrorIconImg" src={ErrorIcon} />
                    Please enter correct university name
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mobile_otp">
              <div className="mobile-div">
                <div
                  className="full-name"
                  style={isOpenMobile ? { color: "#0C0CBF" } : null}
                  onBlur={(e) => {
                    setIsOpenMobile(false);
                  }}
                >
                  Phone
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <input
                    type="text"
                    className="mobile-field"
                    placeholder="Phone"
                    value={mobile}
                    onBlur={(e) => {
                      setIsOpenMobile(false);
                    }}
                    onChange={(e) => handleMobile(e)}
                    style={
                      isOpenMobile &&
                      !wrongMobile &&
                      !isAlreadyRegistered &&
                      !enterNumber &&
                      !verifyNumber
                        ? {
                            border: "1px solid #0C0CBF",
                            "box-shadow": "0px 1px 2px rgba(12, 12, 191, 0.25)",
                          }
                        : wrongMobile ||
                          isAlreadyRegistered ||
                          enterNumber ||
                          verifyNumber
                        ? {
                            border: "1px solid #E60C0B",
                            "box-shadow": "0px 1px 2px rgba(12, 12, 191, 0.25)",
                          }
                        : null
                    }
                    onClick={(e) => handleActiveMobile(e)}
                  />

                  <div
                    className={displayOtp ? "resend" : "get-otp"}
                    hidden={
                      (isLoggedIn &&
                        parseInt(
                          JSON.parse(localStorage.loginDetails).user_profile
                            .is_otp_verified
                        ) === 1 &&
                        !(
                          isLoggedIn &&
                          JSON.parse(localStorage.loginDetails).user_profile
                            .mobile !== mobile
                        )) ||
                      (displayOtp && countDownStart)
                    }
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleGetOtp(e)}
                  >
                    {displayOtp ? "Resend" : "Get Otp"}
                  </div>
                </div>
                {isEmptyMobile ? (
                  <div className="EmptyFieldError">
                    <img style={{ marginRight: "3px" }} src={WarningIcon} />
                    Please fill out this field
                  </div>
                ) : null}

                {wrongMobile ? (
                  <div className="EightError">
                    <img className="ErrorIconImg" src={ErrorIcon} />
                    Please enter correct mobile number
                  </div>
                ) : null}
                {enterNumber ? (
                  <div className="EightError">
                    <img className="ErrorIconImg" src={ErrorIcon} />
                    Please enter your mobile number
                  </div>
                ) : null}
                {verifyNumber ? (
                  <div className="EightError">
                    <img className="ErrorIconImg" src={ErrorIcon} />
                    Please verify your mobile number
                  </div>
                ) : null}

                {isAlreadyRegistered ? (
                  <div className="EightError1">
                    <img className="ErrorIconImg1" src={ErrorIcon} />
                    {mobileErrorMessage}
                  </div>
                ) : null}

                <div hidden={!countDownStart} className="otp-resend-timer">
                  You can resend OTP after:{" "}
                  <span style={{ color: "#0C0CBF" }}>{seconds} seconds</span>
                </div>
              </div>
              <div
                className={
                  displayOtp && countDownStart
                    ? "otp-div0"
                    : displayOtp
                    ? "otp-div"
                    : "otp-div1"
                }
              >
                <div
                  className="full-name"
                  style={isOpenOtp ? { color: "#0C0CBF" } : null}
                  onBlur={(e) => {
                    setIsOpenOtp(false);
                  }}
                >
                  Enter OTP
                </div>
                <input
                  type="text"
                  className="otp-field"
                  placeholder="Enter Otp"
                  value={otp}
                  onBlur={(e) => {
                    setIsOpenOtp(false);
                  }}
                  onChange={(e) => handleOtp(e)}
                  style={
                    isOpenOtp && !isWrongOtp
                      ? {
                          border: "1px solid #0C0CBF",
                          "box-shadow": "0px 1px 2px rgba(12, 12, 191, 0.25)",
                        }
                      : isWrongOtp
                      ? {
                          border: "1px solid #E60C0B",
                          "box-shadow": "0px 1px 2px rgba(12, 12, 191, 0.25)",
                        }
                      : null
                  }
                  onClick={(e) => handleActiveOtp(e)}
                />
                {isEmptyOtp && displayOtp ? (
                  <div className="EmptyFieldError">
                    <img style={{ marginRight: "3px" }} src={WarningIcon} />
                    Please fill out this field
                  </div>
                ) : null}
                {isWrongOtp ? (
                  <div className="EightError">
                    <img className="ErrorIconImg" src={ErrorIcon} />
                    {otpErrorMessage}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="termCondition">
              <input
                type="checkbox"
                name="termConditionCheckbox"
                value={termChecked}
                onChange={handleTermCheck}
              />
              <label for="termCondition">
                <div
                  className="termConditionCheckbox"
                  onClick={(e) => {
                    setOpenTnc(true);
                  }}
                >
                  {" "}
                  I accept{" "}
                  <span className="termAndCondition">Terms & Conditions</span>
                </div>
              </label>
            </div>
            {termCheckedError ? (
              <div className="EightError">
                <img className="ErrorIconImg" src={ErrorIcon} />
                Please accept terms and conditions
              </div>
            ) : null}

            <TAndCLoan isOpen={openTnc} onClose={() => setOpenTnc(false)} />
            <div className="apply-now" onClick={(e) => handleApplyNow(e)}>
              Apply Now
            </div>
          </div>
        ) : (
          <div className="loan-right1">
            <div className="thank-you-heading">
              Thank you for applying for a loan
            </div>
            <div className="thank-you-text">
              Our partner banks will get in touch with you shortly
            </div>
          </div>
        )} */}
        <div className="loan-section-container-wrapper">
          <div className="loan-section-item-wrapper">
            <div className="loan-section-item">
              <img
                src="/goseeko/images/loan-img-1.svg"
                alt=""
                className="loan-item-image"
              />
              <div className="loan-item">
                Get in touch with us by filling the form
              </div>
            </div>
            <div className="loan-section-item">
              <img
                src="/goseeko/images/loan-img-2.svg"
                alt=""
                className="loan-item-image"
              />
              <div className="loan-item">
                Your application will be processed by the leading banks
              </div>
            </div>
            <div className="loan-section-item">
              <img
                src="/goseeko/images/loan-img-3.svg"
                alt=""
                className="loan-item-image"
              />
              <div className="loan-item">
                Our partner banks will get in touch with you to provide you a
                hassle free loan
              </div>
            </div>
          </div>
          <a href={`${process.env.REACT_APP_WEB_URL}home/student-loan`}>
            <div className="loan-submit">Know more</div>
          </a>
        </div>
      </div>
    </div>
  );
};

const Power = (props) => {
  const handleGoToCourse = (e) => {
    if (false && localStorage.userToken && localStorage.loginDetails) {
      window.location.href =
        process.env.REACT_APP_WEB_URL +
        (localStorage.getItem("next_url")
          ? `studymaterial/${localStorage.getItem("next_url")}`
          : ``);
    } else {
      window.location.href = `${process.env.REACT_APP_WEB_URL}${props.bottomTryForFreeLink}`;
    }
  };

  return (
    <div className="power-container" style={{ backgroundColor: "#F3F3FC" }}>
      <link rel="preload" as="image" href={`${props.girlsvg.url}`} />
      <LazyLoadImage
        className="girl-standing"
        style={{ height: "169.21px" }}
        src={`${props.girlsvg.url}`}
      />
      <div className="inner-power-container">
        <div className="smart-learning">
          {renderHTML(props.numberOfStudents)}
        </div>
        <button
          className="home-page-try-for-free-button"
          onClick={(e) => handleGoToCourse(e)}
        >
          {false && localStorage.userToken && localStorage.loginDetails
            ? `Go To Course`
            : props.bottomTryForFreeText}
        </button>
      </div>
    </div>
  );
};

const Download = (props) => {
  const handleGetApp = (e) => {
    window.location.href = props.googlePlayAppLink;
  };
  return (
    <div className="download-container">
      <link rel="preload" as="image" href={`${props.mobilesvg.url}`} />
      <LazyLoadImage src={`${props.mobilesvg.url}`} />
      <div className="text-and-button-container">
        <text className="download-our-app">
          {renderHTML(props.downloadApp)}
        </text>
        <LazyLoadComponent>
          <button
            className="google-play"
            onClick={(e) => handleGetApp(e)}
          ></button>
        </LazyLoadComponent>
      </div>
    </div>
  );
};

function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

const MiddleContainer = (props) => {
  return (
    <>
      <div className="parent-middle-section-container">
        <div className="newHomepageGroup1">
          <img src={group1} />
        </div>

        <div className="newHomepageGroup2">
          <img src={group2} />
        </div>

        <div className="newHomepageGroup3">
          <img src={group3} />
        </div>
        <div className="middle-section-container">
          <div className="middle-section-heading">{props.studySmart}</div>
          <div className="middle-section-inner-container">
            {props.middleSection.map((data, index) => (
              <div>
                <div className={index % 2 == 0 ? "even-column" : "odd-column"}>
                  <div className="title-subtitle-container">
                    <text className="title-heading-middlesection">
                      {data.title}
                    </text>
                    <text className="subtitle-heading-middlesection">
                      {data.subtitle}
                    </text>
                  </div>
                  <link rel="preload" as="image" href={`${data.img.url}`} />
                  <LazyLoadImage
                    src={`${data.img.url}`}
                    className={index % 2 === 0 ? "even-image" : "odd-image"}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const NewHomepage = () => {
  const [whatsappMessage, setWhatsappMessage] = useState([]);
  const [whatsappButtonText, setWhatsappButtonText] = useState("");
  const [homeBanner, setHomeBanner] = useState([]);
  const [modalSection, setModalSection] = useState({});
  const [display, setDisplay] = useState("none");
  const [verifyEmailModalFlag, setVerifyEmailModalFlag] = useState(false);
  const [university, setUniversity] = useState([]);
  const [universityTitle, setUniversityTitle] = useState("");
  const [testimonial, setTestimonial] = useState([]);
  const [studentLove, setStudentLove] = useState("");
  const [studySmart, setStudySmart] = useState("");
  const [downloadApp, setDownloadApp] = useState("");
  const [numberOfStudents, setNumberOfStudents] = useState("");
  const [middleSection, setMiddleSection] = useState([]);
  const [girlsvg, setGirlsvg] = useState({});
  const [mobilesvg, setMobilesvg] = useState({});
  const [googlePlayAppLink, setGooglePlayAppLink] = useState("");
  const [bottomTryForFreeLink, setBottomTryForFreeLink] = useState("");
  const [bottomTryForFreeText, setBottomTryForFreeText] = useState("");
  const query = useQuery();
  const from = query.get("from");
  const user_id = query.get("user_id");
  const tokenForVerify = query.get("token");
  const toggleVerifyEmailModal = () => {
    {
      setVerifyEmailModalFlag(!verifyEmailModalFlag);
    }
  };
  useEffect(() => {
    if (from == "user_verify") {
      if (localStorage.loginDetails) {
        if (user_id && tokenForVerify) {
          let headers = {
            app: "react",
          };
          let data = {
            user_id: user_id,
            token: tokenForVerify,
          };
          axios
            .post(process.env.REACT_APP_API_DOMAIN + "/verifyEmail", data, {
              headers: headers,
            })
            .then((response) => {
              if (response.data.ack === 1) {
                Toast(
                  "Thank you for verifying your account.You can now sign in to your account to access the study material",
                  "email",
                  true,
                  "Account Verification successful!"
                );
              } else {
                Toast(`${response.data.msg}`, "email", true);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        window.location.replace(
          `/profile-authentication?from=user_verify&user_id=${user_id}&token=${tokenForVerify}`
        );
      }
    }
  }, []);
  useEffect(() => {
    if (
      !(from == "user_verify") &&
      localStorage.getItem("loginDetails") &&
      !JSON.parse(localStorage.getItem("loginDetails")).user_profile
        .is_verified &&
      localStorage.getItem("fromLogin") == 1 &&
      !JSON.parse(localStorage.getItem("loginDetails")).academic_combination &&
      localStorage.getItem("is_dialog")
    ) {
      setVerifyEmailModalFlag(true);
    }
  }, []);

  useEffect(() => {
    ReactGa.initialize(process.env.REACT_APP_GTAG_TRACKING_ID);
    ReactGa.pageview(window.location.pathname + window.location.search);
    // ReactGA.event({
    //     category: "User",
    //     action: "Home Banner",
    // });
    try {
      setTimeout(() => {
        window["zsShowPopup"]();
        // alert("done")
      }, 10000);
    } catch (e) {
      console.log(e);
      // alert("error")
    }

    try {
      setTimeout(() => {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
          window.dataLayer.push(arguments);
        }

        gtag("js", new Date());
        gtag("event", "conversion", {
          send_to: `${process.env.REACT_APP_GTAG_ID}/eXDTCNTJuP0BEJnq3bkC`,
        });
        gtag("config", process.env.REACT_APP_GTAG_ID);

        window.fbq("trackCustom", "Home Banner ");
      }, 2000);
    } catch (e) {
      console.log("fb pixel exception " + e);
    }

    setDisplay("block");
    axios
      .get(strapiWithoutSlash + "/home")
      .then((response) => {
        if (response.data) {
          let data = response.data;

          setHomeBanner(data.home_banner);
          setUniversity(data.my_component.university);
          setUniversityTitle(data.university_title);
          setTestimonial(data.testimonial);
          setStudentLove(data.studentLoveText);
          setNumberOfStudents(data.number_of_students);
          setMiddleSection(data.middle_section);
          setStudySmart(data.studySmart);
          setDownloadApp(data.downloadApp);
          setGirlsvg(data.girlsvg);
          setMobilesvg(data.mobilesvg);
          setGooglePlayAppLink(data.googlePlayAppLink);
          setBottomTryForFreeLink(data.bottomTryForFreeLink);
          setBottomTryForFreeText(data.bottomTryForFreeText);
          setWhatsappMessage(
            data.whatsapp_message
              ? data.whatsapp_message
              : `Try Goseeko! They have college study material exactly as per your university syllabus. https://www.goseeko.com?utm_source=Web&utm_campaign=HomeShare&utm_medium=WA&utm_content=WebClicks`
          );
          setWhatsappButtonText(data.whatsapp_button_text);
        }
        setDisplay("none");
      })
      .catch((e) => {
        setDisplay("none");
      });
  }, []);

  return (
    <>
      <Helmet>
        <meta charset="utf-8"></meta>
        <title>
          Try Goseeko! To access study material exactly as per your university
          syllabus
        </title>
        <meta
          name="description"
          content="Get the best lecture notes for engineering, Commerce, Science & Arts, along with MCQ, Question papers, mapped to your university/college syllabus on Goseeko"
        />
        <meta
          itemprop="name"
          content="Try Goseeko! To access study material exactly as per your university syllabus"
        />
        <meta
          itemprop="description"
          content="Get the best lecture notes for engineering, Commerce, Science & Arts, along with MCQ, Question papers, mapped to your university/college syllabus on Goseeko"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Try Goseeko! To access study material exactly as per your university syllabus"
        />
        <meta
          name="twitter:description"
          content="Get the best lecture notes for engineering, Commerce, Science & Arts, along with MCQ, Question papers, mapped to your university/college syllabus on Goseeko"
        />
        <meta
          name="og:title"
          content="Try Goseeko! To access study material exactly as per your university syllabus"
        />
        <meta
          name="og:description"
          content="Get the best lecture notes for engineering, Commerce, Science & Arts, along with MCQ, Question papers, mapped to your university/college syllabus on Goseeko"
        />
        <meta name="og:url" content="https://www.goseeko.com" />
        <meta name="og:site_name" content="Goseeko" />
        <meta name="og:type" content="website" />
      </Helmet>
      <React.Fragment>
        <VerifyEmailHome
          isOpen={verifyEmailModalFlag}
          onClose={toggleVerifyEmailModal}
        />

        <Navbar />

        {/* <div className="bannerImageMain">
          <img
            src={`/goseeko/images/champion_ship.svg`}
            alt="SkillCamper"
            border="0"
            className="bannerImageMainImg"
          />
           <img
            src={`/goseeko/images/champion_ship_mob.svg`}
            alt="SkillCamper"
            border="0"
            className="bannerImageMainMob"
          />
          <div
            onClick={(e) => {
              window.location.href = "https://www.goseeko.com/home/knowledge-champion"
              // sendAccessDataForSkillcamper(
              //   process.env.REACT_APP_SKILLCAMPER_UTM_DIGITAL_MARKETING,
              //   "home"
              // );
            }}
            className="bannerImageBtn enroll"
          ></div>
          <a
            href="https://www.goseeko.com/home/knowledge-champion"
            className="bannerImageBtn skillCamper"
          ></a>
          <div className="bannerImageRegisterBtn"><div className="bannerImageRegisterBtnText">Register to Win</div></div>
        </div> */}
        {/* <div onClick={(e) => {sendAccessDataForSkillcamper(process.env.REACT_APP_SKILLCAMPER_UTM, "home")}} className="skillcamper_main_div">
          <img className="skillcamper_main_icon" src="/goseeko/images/skillcamper_logo.svg"></img>
          <span className="skillcamper_main_text">Assured Job placements, starting salary Rs. 5 lakhs after a 3 month world class Bootcamp in <span style={{ fontWeight: "600" }}>Digital Marketing</span>, <span style={{ fontWeight: "600" }}> Full Stack</span> and <span style={{ fontWeight: "600" }}>Data Science</span> by <span className="skillcamper_main_text_skillcamper" style={{ fontWeight: "700", color: "#0C0CBF" }}>SkillCamper</span></span>
          <button className="skillcamper_main_button">Enroll Now</button>
        </div> */}
        {/* <Hackathon /> */}
        {/* <HomeBanner homeBanner={homeBanner} /> */}

        <HomeBannerVideo homeBanner={homeBanner} />
        <div
          onClick={() => {
            if (detectMob()) {
              window.open(
                `https://api.whatsapp.com/send?l=en&text=${encodeURIComponent(
                  whatsappMessage
                )}`,
                "",
                "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
              );
            } else {
              window.open(
                `https://web.whatsapp.com/send?l=en&text=${encodeURIComponent(
                  whatsappMessage
                )}`,
                "",
                "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
              );
            }
          }}
          className={"whatsapp-home-support"}
        >
          <div className={"whatsapp-home-support-text"}>
            <span>{whatsappButtonText}</span>
          </div>
          <link
            rel="preload"
            as="image"
            href={`https://i.ibb.co/jy0rCLQ/whatsapp-support.png`}
          />

          <LazyLoadImage
            src={`https://i.ibb.co/jy0rCLQ/whatsapp-support.png`}
            style={{ marginTop: "13px", width: "44px", height: "44px" }}
            alt="image"
          />
        </div>
        <University universityTitle={universityTitle} university={university} />
        <MiddleContainer
          middleSection={middleSection}
          studySmart={studySmart}
        />
        <Testimonial testimonial={testimonial} studentLove={studentLove} />
        {/* <LoanSection /> */}
        <Power
          numberOfStudents={numberOfStudents}
          girlsvg={girlsvg}
          bottomTryForFreeLink={bottomTryForFreeLink}
          bottomTryForFreeText={bottomTryForFreeText}
        />
        <Download
          downloadApp={downloadApp}
          mobilesvg={mobilesvg}
          googlePlayAppLink={googlePlayAppLink}
        />
        <Footer />
      </React.Fragment>
    </>
  );
};
export default NewHomepage;
