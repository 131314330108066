import {useHistory} from "react-router-dom";
import "./kitabee.css";
import GoSeeko from "../../assets/kitabee/Go-seeko.png";
import React, {useEffect, useState} from "react";

import axios from "axios";
import VideoCamera from "../../assets/kitabee/video_camera.svg";
import Point from "../../assets/kitabee/point.svg";
import LinkedIn from "../../assets/kitabee/linkedin.svg";
import Instagram from "../../assets/kitabee/instagram.svg";
import Youtube from "../../assets/kitabee/youtube.svg";
import Facebook from "../../assets/kitabee/facebook.svg";
import Twitter from "../../assets/kitabee/twitter.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousals from "./Carousals";
import useQuery from "../../goseeko/pages/Query";
import GetQueryJson from "../../goseeko/pages/QueryJson";
import ReactGa, * as ReactGA from "react-ga";

const Kitabee = (props) => {
    const id = props.match.params.id;

    const [logoUrl, setLogoUrl] = useState(process.env.REACT_APP_PHP_DOMAIN);

    const history = useHistory();
    const [toShow, setToShow] = useState(2);
    const [getHeightCourse, setHeightCourse] = useState(0);
    const [data, setData] = useState([]);
    const [title, setTitle] = useState("");
    const [titleImage, setTitleImage] = useState("");
    const [tagLine, setTagLine] = useState("");
    const [feature1, setFeature1] = useState("");
    const [feature2, setFeature2] = useState("");
    const [feature3, setFeature3] = useState("");
    const [feature4, setFeature4] = useState("");
    const [applyButton, setApplyButton] = useState("");
    const [introVideo, setIntroVideo] = useState("");
    const [whatWeOffer, setWhatWeOffer] = useState("");
    const [whyWeBest, setWhyWeBest] = useState("");
    const [pricing, setPricing] = useState("");
    const [curriculum, setCurriculum] = useState("");
    const [testimonials, setTestimonials] = useState("");
    const [whatWeOfferTitle, setWhatWeOfferTitle] = useState("");
    const [whyWeBestTitle, setWhyWeBestTitle] = useState("");
    const [pricingTitle, setPricingTitle] = useState("");
    const [curriculumTitle, setCurriculumTitle] = useState("");
    const [testimonialsTitle, setTestimonialsTitle] = useState("");
    const [aboutUsTitle, setAboutUsTitle] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [instagram, setInstagram] = useState("");
    const [youtube, setYoutube] = useState("");
    const [facebook, setFacebook] = useState("");
    const [twitter, setTwitter] = useState("");
    const [skilledPrice, setSkilledPrice] = useState([]);

    const [aboutUsLongText, setAboutUsLongText] = useState("");
    const [partnerLogoAbout, setPartnerLogoAbout] = useState("");
    const [whatWeOfferArray, setWhatWeOfferArray] = useState([]);
    const [whyWeBestArray, setWhyWeBestArray] = useState([]);
    const [curriculumArray, setCurriculumArray] = useState([]);
    const [testimonialArray, setTestimonialArray] = useState([]);
    const [studentTestimonial, setStudentTestimonial] = useState([]);
    const [partnerLogo, setPartnerLogo] = useState("");
    const [aboutUs, setAboutUs] = useState("");
    const [modal, setModal] = useState(false);

    const [showMore, setShowMore] = useState(false);

    const [purchased, setPurchased] = useState(false);
    const [loggedIn, setLoggedIn] = useState(true);
    const [userId, setUserId] = useState("");
    const [fromApp, setFromApp] = useState(false);
    const [f1, setF1] = useState([]);
    const [f2, setF2] = useState([]);
    const [f3, setF3] = useState([]);
    const [f4, setF4] = useState([]);
    const query = useQuery()
    const getQueryJson = GetQueryJson(2)


    useEffect(() => {
        let queryApp = query.get("app") ? query.get("app") : ""
        if (queryApp == "true") {
            localStorage.setItem("app", "true")
        } else if (queryApp == "false") {
            localStorage.setItem("app", "false")
            if (query.get("token")) {
                localStorage.setItem("userToken", query.get("token"))
            }
            getUserStatusOfCourse()
        }

        let storageApp = localStorage.getItem("app") ? localStorage.getItem("app") : ""

        if (storageApp == "true") {
            setFromApp(true)
            setLogoUrl("")

            let tokenUpdate = localStorage.getItem("tokenUpdate") ? localStorage.getItem("tokenUpdate") : ""
            if (tokenUpdate) {
                getUserStatusOfCourse()
            } else {
                if (query.get("token")) {
                    localStorage.setItem("userToken", query.get("token"))
                }
                let headers = {
                    authorization: localStorage.getItem("userToken"),
                    "content-type": "application/json"
                }
                axios.post(process.env.REACT_APP_API_DOMAIN + "/geReactTokenByAppToken", {}, {
                    headers: headers
                }).then((response) => {
                        if (response.data.ack === 1) {
                            localStorage.setItem("userToken", response.data.token)
                            // localStorage.setItem("react", "react")
                            localStorage.setItem("tokenUpdate", "true")
                            localStorage.setItem("userId", query.get("user_id"))
                            setUserId(query.get("user_id"))
                            getUserStatusOfCourse()
                        }
                    }
                ).catch((err) => {
                    getUserStatusOfCourse()

                });
            }
            setUserId(localStorage.getItem("userId"))
        }

        ReactGa.initialize(process.env.REACT_APP_GTAG_TRACKING_ID);
        ReactGa.pageview(window.location.pathname + window.location.search);
        // ReactGA.event({
        //     category: "User",
        //     action: "Get Skilled Course React",
        // });

        try {
            setTimeout(() => {

                window.dataLayer = window.dataLayer || [];

                function gtag() {
                    window.dataLayer.push(arguments);
                }

                gtag("js", new Date());
                gtag("event", "conversion", {
                    send_to: `${process.env.REACT_APP_GTAG_ID}/eXDTCNTJuP0BEJnq3bkC`,
                  });
                  gtag("config", process.env.REACT_APP_GTAG_ID);
                window.fbq("trackCustom", "Get Upskilled Course React ");

            }, 2000);
        } catch (e) {
            console.log("fb pixel exception " + e);
        }

    }, [])


    // let fromAppTemp = false
    // if (localStorage.getItem("react") && localStorage.getItem("react") == "react") {
    //     if (localStorage.getItem("user_id") && localStorage.getItem("user_id") != "null") {
    //         setUserId(localStorage.getItem("user_id"))
    //         setFromApp(true)
    //         fromAppTemp = true
    //     }
    // } else {

    //     if (query.get("token")) {
    //         if (query.get("app") && query.get("app") == "true") {
    //             localStorage.setItem("react", "app")
    //             localStorage.setItem("user_id", query.get("user_id"))
    //             setUserId(query.get("user_id"))
    //             setFromApp(true)
    //             fromAppTemp = true
    //         } else {
    //             localStorage.setItem("react", "react")
    //             localStorage.removeItem("user_id")
    //             setUserId(0)
    //             setFromApp(false)
    //             fromAppTemp = false
    //         }
    //         localStorage.setItem("userToken", query.get("token"))
    //     }


    // console.log("fromApp")
    // console.log(fromApp)
    // console.log(localStorage.getItem("react"))
    // console.log(localStorage.getItem("react") != "react")


    // if (fromAppTemp && localStorage.getItem("react") && localStorage.getItem("react") != "react") {
    //     let headers = {
    //         authorization: localStorage.getItem("userToken"),
    //         "content-type": "application/json"
    //     }
    //     axios.post(process.env.REACT_APP_API_DOMAIN + "/geReactTokenByAppToken", {}, {
    //         headers: headers
    //     }).then((response) => {
    //         if (response.data.ack === 1) {
    //             localStorage.setItem("userToken", response.data.token)
    //             localStorage.setItem("react", "react")
    //         }
    //     }
    //     ).catch((err) => {

    //     });
    // }
    // }


    const getUserStatusOfCourse = () => {
        let headers2 = {
            authorization: localStorage.getItem("userToken"),
            "content-type": "application/json",
            app: "react",
        }
        let data = {
            get_skilled_id: id
        }
        axios.post(process.env.REACT_APP_API_DOMAIN + "/getDetailsOfGetSkilled", data, {
            headers: headers2
        }).then((response) => {
                if (response.data.ack === 1) {
                    setPurchased(true)
                } else {
                    setPurchased(false)
                }
            }
        ).catch((err) => {
            setPurchased(false)
            setLoggedIn(false)
            localStorage.clear();
            console.log("running clear ")
        });
    }

    const showFeature = (feature) => {
        var l = feature.split("|");
        return l.map((v) => <li>{v}</li>);
    };

    const scrollToTop = (ele) => {
        var ele = window.document.getElementById(ele);
        ele.scrollIntoView({
            behavior: "smooth",
        });
    };

    const priceOption = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: skilledPrice?.length === 1 ? 1 : 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const testimonialOption = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "10px",
        slidesToShow: 3,
        speed: 500,
    };

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_DOMAIN}/getSkilled/` + id)
            .then((response) => {
                if (response.data.ack === 1) {
                    var data = response.data.data;
                    setPartnerLogo(data.partner_logo);
                    setTitle(data.title);
                    setTitleImage(data.title_image);
                    setTagLine(data.tag_line);
                    setApplyButton(data.apply_button);
                    setIntroVideo(data.intro_video);
                    setFeature1(data.feature_1);
                    setFeature2(data.feature_2);
                    setFeature3(data.feature_3);
                    setFeature4(data.feature_4);
                    setWhatWeOffer(data.what_we_offer);
                    setWhyWeBest(data.why_we_best);
                    setPricing(data.pricing);
                    setCurriculum(data.curriculum);
                    setTestimonials(data.testimonials);
                    setAboutUs(data.about_us);
                    setPartnerLogoAbout(data.partner_logo_about);
                    setWhatWeOfferTitle(data.what_we_offer_title);
                    setWhyWeBestTitle(data.why_we_best_title);
                    setPricingTitle(data.pricing_title);
                    setCurriculumTitle(data.curriculum_title);
                    setTestimonialsTitle(data.testimonials_title);
                    setAboutUsTitle(data.about_us_title);
                    setAboutUsLongText(data.about_us_long_text);
                    setLinkedIn(data.linkedin);
                    setInstagram(data.instagram);
                    setYoutube(data.youtube);
                    setFacebook(data.facebook);
                    setTwitter(data.twitter);
                    setSkilledPrice(data.get_skilled_prices);
                    setWhatWeOfferArray(data.what_we_offer_array);
                    setWhyWeBestArray(data.why_we_best_array);
                    setCurriculumArray(data.curriculum_array);
                    setTestimonialArray(data.testimonial_array);
                    setStudentTestimonial(data.testimonial_array);

                    var f_1 = data.feature_1.split("|");
                    var f_2 = data.feature_2.split("|");
                    var f_3 = data.feature_3.split("|");
                    var f_4 = data.feature_4.split("|");

                    setF1(f_1);
                    setF2(f_2);
                    setF3(f_3);
                    setF4(f_4);
                }
            })
            .catch((err) => {
            });

        window.fbq("trackCustom", `dynamicPage-${id}`);
    }, []);


    return (


        <div className="dynamicPage-container">
            <div className="dynamic-header">
                <a href={logoUrl}>
                    <img className="dynamic-h-3" src={GoSeeko} alt=""/>
                </a>
                <a href={process.env.REACT_APP_PHP_DOMAIN}>
                    <img
                        className="dynamic-h-4"
                        src={partnerLogo}
                        style={{height: "45px", marginLeft: "1px"}}
                        alt=""
                    />
                </a>
            </div>
            <div className="dynamicPageTop">
                <div className="dynamicPageTopHead kitabeeBox whiteText">
                    <h2 className="whiteText">{title}</h2>
                </div>
                <hr className="white"/>
                <div className="dynamicPageTopBody kitabeeBox">
                    <div className="dynamic-dptb-left">
                        <h1 className="dynamic-dptb-l-1 whiteText">{tagLine}</h1>
                        <div className="dynamic-dptb-l-2">
                            <div
                                className={`dynamic-dptb-l-2-1${purchased ? " disabledYellow" : ""
                                }`}
                                onClick={() => {
                                    if (!purchased) {
                                        window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSdj3PskzrR_q4TneICvo3-NO8sr53xCdLy1fEdWx3Jrxe7Vmw/viewform?usp=sf_link";
                                    }
                                }}
                            >

                                {purchased ? "Already Purchased!" : applyButton}

                            </div>
                            {introVideo ? (
                                <div
                                    className="dynamic-dptb-l-2-2 whiteText"
                                    onClick={() => setModal(true)}
                                >
                                    <img src={VideoCamera}/>
                                    <span>Intro Video</span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="dynamic-dptb-right">
                        <img src={titleImage}/>
                    </div>
                    <div className="dynamic-dptb-bottom">
                        <div className="dynamic-dptb-bottom-card">
                            <div className="dynamic-dptb-bottom-card-title">{f1[0]}</div>
                            <div className="dynamic-dptb-bottom-card-body">{f1[1]}</div>
                        </div>
                        <div className="dynamic-dptb-bottom-card">
                            <div className="dynamic-dptb-bottom-card-title">{f2[0]}</div>
                            <div className="dynamic-dptb-bottom-card-body">{f2[1]}</div>
                        </div>
                        <div className="dynamic-dptb-bottom-card">
                            <div className="dynamic-dptb-bottom-card-title">{f3[0]}</div>
                            <div className="dynamic-dptb-bottom-card-body">{f3[1]}</div>
                        </div>
                        <div className="dynamic-dptb-bottom-card">
                            <div className="dynamic-dptb-bottom-card-title">{f4[0]}</div>
                            <div className="dynamic-dptb-bottom-card-body">{f4[1]}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="dynamicPageTabBox">
                <div className="dynamicPageTabs kitabeeBox">
                    {pricing ? (
                        <div onClick={() => scrollToTop("pricing")}>{pricingTitle}</div>
                    ) : null}

                    {curriculum ? (
                        <div onClick={() => scrollToTop("curriculum")}>
                            {curriculumTitle}
                        </div>
                    ) : null}

                    {aboutUs ? (
                        <div onClick={() => scrollToTop("about")}>{aboutUsTitle}</div>
                    ) : null}

                    {testimonials ? (
                        <div onClick={() => scrollToTop("testimonials")}>
                            {testimonialsTitle}
                        </div>
                    ) : null}
                </div>
            </div>

            <div>
                <div className="title-font">{whatWeOfferTitle}</div>
                <div className="kitabeeBox dynamicPage-what-we-offer-container">
                    {whatWeOfferArray.map((v) => {
                        return (
                            <div className="dynamicPage-what-we-offer-card">
                                <img src={v.image}/>
                                <div>{v.title}</div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="skills-will-learn">
                <div className="swl-top-border"></div>
                <div className="swl-bottom-border"></div>
                <div className="swl-header"> {whyWeBestTitle}</div>
                <div className="swl-skills-section dynamicPage">
                    {whyWeBestArray.map((v) => {
                        return (
                            <div className="swl-skill text-center">
                                <span className="swl-arrow">
                                    <svg
                                        width="8"
                                        height="12"
                                        viewBox="0 0 8 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <mask id="path-1-inside-1" fill="white">
                                            <path
                                                d="M0.589844 10.59L5.16984 6L0.589844 1.40999L1.99984 -7.62939e-06L7.99984 6L1.99984 12L0.589844 10.59Z"/>
                                        </mask>
                                        <path
                                            d="M0.589844 10.59L5.16984 6L0.589844 1.40999L1.99984 -7.62939e-06L7.99984 6L1.99984 12L0.589844 10.59Z"
                                            fill="#0C0CBF"
                                        />
                                        <path
                                            d="M0.589844 10.59L-1.53379 8.47099L-3.65048 10.5923L-1.53148 12.7113L0.589844 10.59ZM5.16984 6L7.29348 8.119L9.40786 6L7.29348 3.88099L5.16984 6ZM0.589844 1.40999L-1.53148 -0.711326L-3.65048 1.40768L-1.53379 3.529L0.589844 1.40999ZM1.99984 -7.62939e-06L4.12116 -2.12133L1.99984 -4.24265L-0.121478 -2.12133L1.99984 -7.62939e-06ZM7.99984 6L10.1212 8.12132L12.2425 6L10.1212 3.87868L7.99984 6ZM1.99984 12L-0.121478 14.1213L1.99984 16.2426L4.12116 14.1213L1.99984 12ZM2.71348 12.709L7.29348 8.119L3.04621 3.88099L-1.53379 8.47099L2.71348 12.709ZM7.29348 3.88099L2.71348 -0.709011L-1.53379 3.529L3.04621 8.119L7.29348 3.88099ZM2.71116 3.53131L4.12116 2.12131L-0.121478 -2.12133L-1.53148 -0.711326L2.71116 3.53131ZM-0.121478 2.12131L5.87852 8.12132L10.1212 3.87868L4.12116 -2.12133L-0.121478 2.12131ZM5.87852 3.87868L-0.121478 9.87868L4.12116 14.1213L10.1212 8.12132L5.87852 3.87868ZM4.12116 9.87868L2.71116 8.46868L-1.53148 12.7113L-0.121478 14.1213L4.12116 9.87868Z"
                                            fill="#0C0CBF"
                                            mask="url(#path-1-inside-1)"
                                        />
                                    </svg>
                                </span>
                                <span>{v.title}</span>
                            </div>
                        );
                    })}
                </div>
            </div>

            {/*Price Plan*/}

            {pricing ? (
                <div className="kitabeeBox" id="pricing">
                    <div className="title-font">{pricingTitle}</div>

                    <Slider {...priceOption}>
                        {skilledPrice.map((v) => (
                            <div
                                className={`dynamicPage-pricing-card${skilledPrice?.length === 1 ? " single" : ""
                                }`}
                            >
                                <div className="dynamicPage-pricing-card-header">
                                    <div>{v.title}</div>
                                    <div>{v.sub_title}</div>
                                    <div>{v.price}</div>
                                </div>
                                <div style={{backgroundColor: "#a0a0a0"}}></div>
                                <div className="dynamicPage-pricing-card-body">
                                    <h5 className="yellowText"> Features </h5>
                                    <ul className="colorUl">{showFeature(v.features)}</ul>
                                </div>
                                <a
                                    href={
                                        purchased
                                            ? `${process.env.REACT_APP_PHP_DOMAIN}/get-skilled?section=getskill`
                                            : loggedIn
                                            ? fromApp && userId
                                                ? `${process.env.REACT_APP_PHP_DOMAIN}/study/checkout?type=${v.get_skilled_id}&price_id=${v.id}`
                                                : `${process.env.REACT_APP_PHP_DOMAIN}/study/checkout?type=${v.get_skilled_id}&price_id=${v.id}`
                                            : `${process.env.REACT_APP_PHP_DOMAIN}/profile-authentication?from=get-skilled&skilled_id=${v.get_skilled_id}&price_id=${v.id}&${getQueryJson}`

                                    }

                                    onClick={() => {
                                        if (!purchased)
                                            window.fbq("trackCustom", `dynamicPageBuy-${id}-${v.id}`);
                                    }}
                                >
                                    <div
                                        className={`dynamicPage-pricing-card-action${purchased ? " disabledYellow" : ""
                                        }`}
                                    >
                                        {purchased && purchased === v.id
                                            ? "Already Purchased!"
                                            : "Buy Now"}
                                    </div>
                                </a>
                            </div>
                        ))}
                    </Slider>
                </div>
            ) : null}

            {curriculum ? (
                <div className="dynamicPage-curriculum-container" id="curriculum">
                    <div className="kitabeeBox">
                        <div className="title-font">{curriculumTitle}</div>
                        <div className="dynamicPage-curriculum-body">
                            {curriculumArray.map((curriculum, index) => {
                                if (index < 10 || showMore) {
                                    return (
                                        <div key={index} className="dynamicPage-curriculum-single">
                                            <img src={Point}/> {curriculum.title}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                        <div className="dynamicPage-curriculum-showMore">
                            {curriculumArray.length > 10 ? (
                                <span
                                    style={{fontWeight: "bolder"}}
                                    onClick={() => setShowMore(!showMore)}
                                >
                                    {!showMore ? "Show More" : "Show Less"}
                                </span>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}

            {aboutUs ? (
                <div className="dynamicPage-aboutUs-container kitabeeBox" id="about">
                    <div className="dynamicPage-aboutUs-left">
                        <img src={partnerLogoAbout}/>
                    </div>
                    <div className="dynamicPage-aboutUs-right">
                        <div className="dynamicPage-a-r-1">{aboutUsTitle}</div>
                        <div className="dynamicPage-a-r-2">{aboutUsLongText}</div>
                        <div className="dynamicPage-a-r-3">
                            {linkedIn ? (
                                <a href={linkedIn}>
                                    <img src={LinkedIn}/>
                                </a>
                            ) : null}
                            {youtube ? (
                                <a href={youtube}>
                                    <img src={Youtube}/>
                                </a>
                            ) : null}

                            {instagram ? (
                                <a href={instagram}>
                                    <img src={Instagram}/>
                                </a>
                            ) : null}

                            {facebook ? (
                                <a href={facebook}>
                                    <img src={Facebook}/>
                                </a>
                            ) : null}
                            {twitter ? (
                                <a href={twitter}>
                                    <img src={Twitter}/>
                                </a>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}

            {testimonials ? (
                <div className="dynamicPages-testimonial-container" id="testimonials">
                    <div className="title-font whiteText">{testimonialsTitle}</div>
                    <div className="dynamicPages-testimonial-body">
                        {studentTestimonial.length > 0 && (
                            <Carousals carouselArray={studentTestimonial}/>
                        )}
                    </div>
                </div>
            ) : null}
            {modal ? (
                <div className="dynamicPage-modalContainer">
                    <div className="dynamicPage-modal">
                        <div className="dynamicPage-modal-header">
                            <span onClick={() => setModal(false)}>&times;</span>
                        </div>
                        <div className="dynamicPage-modal-body">
                            {introVideo?.includes("https://www.youtube.com") ? (
                                <iframe
                                    src={
                                        introVideo?.includes("embed")
                                            ? introVideo
                                            : introVideo.replace("watch?v=", "embed/")
                                    }
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            ) : (
                                <video controls src={introVideo}></video>
                            )}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Kitabee;
