import React, { useEffect, useState } from "react";
import {
  modalContainer,
  modal,
  modalHeader,
  modalTitle,
  modalClose,
  modalBody,
  closed,
  miniModal,
  blueHead,
  lockImage,
  modalBodyHeading,
  modalBodyContent,
  modalBodyList,
  modalBodyButtonLeft,
  modalBodyButtonRight,
  modalBodySubtext,
  eRequired,
  modalTitleImg,
  emailSent,
  deciding,
} from "./style.module.css";
import EmailIcon from "../../../assets/emailIcon.svg";
import axios from "axios";

const VerifyEmailHome = ({ isOpen, onClose, whiteHead, blueHeader }) => {
  const [emailSentFlag, setEmailSentFlag] = useState(false);
  const handleAuth = (e) => {
    e.preventDefault();
    window.location.href = `${process.env.REACT_APP_PHP_DOMAIN}/contact-us`;
  };
  const handleResendEmail = (e) => {
    e.preventDefault();
    const url = process.env.REACT_APP_API_DOMAIN + `/resendEmail`;
    let email = `${JSON.parse(localStorage.getItem("loginDetails")).user_profile.email} `
    let data = {

      email: email,

    };
    const options = {
      method: "POST",
      headers: {
        authorization: localStorage.userToken,
        "content-type": "application/json",
        app: "react",
      },
      url: url,
      data: data,
    };
    axios(options).then((response) => {
      // console.log(response.data.counter);
      // setCounterForStudyPage(response.data.counter);
      console.log(response)
      if (response.status == 200) {
        setEmailSentFlag(true)
        setTimeout(() => {
          setEmailSentFlag(false)
        }, 5000);
      }
    }).catch((e) => {
      console.log(e);
    });
  };

  return isOpen ? (
    <div className={`${modalContainer} ${isOpen ? "" : closed}`}>
      <div className={`${modal} ${whiteHead ? miniModal : ""}`}>
        <div
          className={`${modalHeader} noSelectDefault ${blueHeader ? blueHead : ""
            }`}
        >

          <div className={`${modalTitle}`}>
            {emailSentFlag ? <div className={emailSent}>Email Verification Link Sent</div> : null}
            <img className={`${modalTitleImg}`} src={EmailIcon} />
            <div className={`${eRequired}`}>
              Email Verification Required
            </div>
          </div>

        </div>
        <div className={`${modalBody}`}>

          <div className={`${modalBodyHeading}`}>
            {`Hi ${JSON.parse(localStorage.getItem("loginDetails")).user_profile.full_name}!`}
          </div>
          <div className={`${modalBodyContent}`}>
            Please verify your account using verification link sent to <strong>{`${JSON.parse(localStorage.getItem("loginDetails")).user_profile.email} `}</strong><br />
            Can’t find it? Check your spam folder or click on resend email button.

          </div>
          <div className={deciding}>
            <button
              onClick={(e) => handleResendEmail(e)}
              className={`${modalBodyButtonLeft}`}
            >
              Resend Email
            </button>
            {onClose ? <button
              onClick={onClose}
              className={`${modalBodyButtonRight}`}
            >
              Continue to Goseeko
            </button> : null}

          </div>
          <div className={`${modalBodySubtext}`}>
            {`Facing other issues? `}
            <span
              onClick={(e) => handleAuth(e)}
              style={{ "cursor": "pointer", "color": "#488cc7" }}

            >
              contact us
            </span>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default VerifyEmailHome;
