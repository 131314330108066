const { useLocation } = require("react-router");

const GetQueryJson = (type = 1) => {
    let query = new URLSearchParams(useLocation().search);
    let completeQuery = {}
    let completeQueryString = ""
    for (const param of query) {
        completeQueryString += param[0]+"="+(param[1].includes("/") ? encodeURIComponent(param[1]) : param[1] )+"&"
        completeQuery[param[0]] = param[1]
    }
    if (type == 1){
        return completeQuery
    }else{
        return completeQueryString
    }
}

export default GetQueryJson
