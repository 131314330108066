const { base64encode, base64decode } = require("nodejs-base64");
const LocalStorageBase64 = (type,name,value) => {
  if (type === "set") {
    return localStorage.setItem(name,base64encode(value));
  } else if (type === "get") {
    return localStorage.getItem(name) ? base64decode(localStorage.getItem(name)) : 0;
  }
};

export default LocalStorageBase64;
