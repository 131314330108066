import React, { useState } from "react";
import "./dynamicCarousal.css";

const Carousals = (props) => {
  const [carousalIndex, setCarouselIndex] = useState(1);
  const [animating, setAnimating] = useState(false);
  const handleClick = (step) => {
    setAnimating(true);
    let index = step + carousalIndex;
    if (index === props.carouselArray.length) {
      index = 0;
    } else {
      if (index === -1) {
        index = props.carouselArray.length - 1;
      }
    }
    setTimeout(() => {
      setCarouselIndex(index);
      setAnimating(false);
    }, 500);
  };
  return (
    <>
      <div className="dynamicCarousal">
        <div
          className="dynamicCarousal-1 dynamicCarousal-card"
          onClick={() => handleClick(-1)}
        >
          <img
            src={
              props.carouselArray[
                carousalIndex === 0
                  ? props.carouselArray.length - 1
                  : carousalIndex - 1
              ]?.image
            }
            className="dynamicCarousal-img"
          />
        </div>
        <div className="dynamicCarousal-2 dynamicCarousal-card">
          <img
            src={props.carouselArray[carousalIndex]?.image}
            className="dynamicCarousal-img"
          />
        </div>
        <div
          className="dynamicCarousal-3 dynamicCarousal-card"
          onClick={() => handleClick(1)}
        >
          <img
            src={
              props.carouselArray[
                carousalIndex === props.carouselArray.length - 1
                  ? 0
                  : carousalIndex + 1
              ]?.image
            }
            className="dynamicCarousal-img"
          />
        </div>
      </div>
    </>
  );
};

export default Carousals;
