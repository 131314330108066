import Tick from "./tick.png"

const Toast = (text, type,onClose,heading) => {
  if(onClose==undefined)
  {
    onClose = false;
  }
  if(heading==undefined)
  {
    heading = '';
  }
  const root = document.getElementById("toast-root");
  const clear = (e) => {
    root.innerHTML = "";
  };
  if(onClose)
  {
    root.innerHTML = `
  
    
    
    <div class="toast ${type}" >
      <img class = "tick" src="${Tick}"/>
      <div class="text">
      ${heading!=='' ?(`<div class="toastTitleHeading">${heading}</div>`):`<div></div>`}
        ${text}</div>
        ${onClose ? (
          `<div class="close" onClick="(function(){
            
            (document.getElementById('toast-root')).innerHTML = '';
            return false;
        })();return false;">
            &times;
          </div>
        `) : `<div></div>`}
      </div>`;
    }
  
  else{
  root.innerHTML = `<div class="toast ${type}" >
        <div class="toastTitle">${type}</div>
        ${text}
      </div>`;}
  setTimeout(() => {
    clear();
  }, 5000);
};

export default Toast;
